import { Button, Image, useWebsite } from '@joehua/dew-components'
import React from 'react'

export function MobileNav({
  isShowDrawer,
  setIsShowDrawer,
}: {
  isShowDrawer: boolean
  setIsShowDrawer: (v: boolean) => void
}) {
  // const { getValue } = useSectionData()
  const {
    website: { sectionValues },
  } = useWebsite()
  const logo = sectionValues?.Image?.logo

  return (
    <div className="fixed lg:hidden top-0 w-full z-30">
      <div className="relative h-16 flex bg-gradient-to-b from-black via-alpha-90 to-alpha-50 shadow-xs overflow-x-hidden px-4 items-center justify-between">
        <Button
          aria-label="open mobile menu"
          className="z-20 text-white w-8 h-8 inline-flex justify-center items-center"
          name="mobile menu"
          icon={isShowDrawer ? 'x' : 'menu'}
          onClick={() => {
            setIsShowDrawer(!isShowDrawer)
          }}
        />
        <Image
          className="absolute h-12"
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          data={{
            value: {
              ...logo,
              wrapperNavItem: { type: 'link', to: { name: 'index', lang: 'vi' } },
            },
            config: { transform: 'w_200' },
            theme: { root: 'w-16 inline-flex' },
          }}
        />
      </div>
    </div>
  )
}
