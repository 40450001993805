import { Button, Nav } from '@joehua/dew-components'
import React from 'react'

export function MobileDrawer({
  isShow,
  onClose,
}: {
  isShow: boolean
  onClose: () => void
}) {
  if (!isShow) {
    return null
  }
  return (
    <div className="w-full fixed top-0 left-0 bg-neutral-900 h-screen z-50 flex flex-col items-start justify-center px-6 py-4">
      <Button
        name="close menu mobile"
        color="white"
        icon="x"
        onClick={onClose}
        className="absolute w-8 h-8 top-0 mt-4 text-neutral-100"
      />
      <div className="">
        <Nav
          onItemClick={onClose}
          data={{
            valueKey: 'menu_chinh',
            themeKey: 'mobile_drawer',
          }}
          path={[]}
        />
      </div>
    </div>
  )
}
