import React from 'react'

import { ServiceCardFragment } from '../../generated/graphql'
import { ServiceCard } from '../ServiceCard'

export function ServiceCardList({ entities }: { entities: ServiceCardFragment[] }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {entities.map((x) => (
        <ServiceCard key={x.id} entity={x} />
      ))}
    </div>
  )
}
