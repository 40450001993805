import { TaxonBreadcrumbItemFragment } from 'src/generated/graphql'

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function getFieldValues<T>(fieldValues: any, code: string, defaultValue?: T) {
  if (!Array.isArray(fieldValues)) {
    return null
  }
  const value: T = fieldValues?.find((x) => x.code == code)?.value
  return value || defaultValue
}

export function makeFontVariables(fonts?: {
  sans?: string
  serif?: string
  mono?: string
}) {
  if (!fonts) {
    return null
  }
  const tmp = Object.keys(fonts)
    .map((k) => `--font-${k}: ${fonts[k]};`)
    .join('\n')
  return `:root {${tmp}}`
}

export function addStyle(url: string) {
  if (typeof document === 'undefined') {
    return
  }
  const style = document.createElement('link')
  style.href = url
  style.rel = 'stylesheet'
  document.head.appendChild(style)
}

export function getBreadcrumbsByTaxon({
  item,
  taxonomyCode,
}: {
  item?: TaxonBreadcrumbItemFragment
  taxonomyCode: string
}) {
  if (!item) {
    return []
  }
  return [
    ...item?.breadcrumbs.map((x) => ({
      to: { name: taxonomyCode, lang: 'vi', params: { slug: x.slug } },
      title: x.title,
    })),
    item && {
      to: { name: taxonomyCode, lang: 'vi', params: { slug: item.slug } },
      title: item.title,
    },
  ].filter(notEmpty)
}
