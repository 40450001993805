import { InfoItem, SectionsOverride } from '@joehua/dew-components'
import { mergeDeepRight } from 'ramda'
import React from 'react'

import { EntryNotFound } from '../../components/EntryNotFound'
// import { Link } from '../../components/Link'
import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { PostContent } from '../../components/PostContent'
import { Seo } from '../../components/Seo'
import { ServiceCardList } from '../../components/ServiceCardList'
import { PostPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function PostPage() {
  const [{ data, fetching }] = useRouteQuery<PostPageQuery>('Post')

  const { route, post, relatedPosts, relatedServices } = data ?? {}

  return (
    <>
      {fetching && <Loading />}

      {post ? (
        <SectionsOverride
          sectionConfigs={route?.sectionConfigs}
          sectionThemes={route?.sectionThemes}
          sectionValues={mergeDeepRight(route?.sectionValues, post.sectionValues)}
        >
          <Seo seo={post.seo} />
          <PostContent entity={post} />
          {relatedServices?.entities.length ? (
            <section className="py-10 lg:py-32 bg-neutral-100">
              <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
                <InfoItem
                  data={{
                    valueKey: 'tieu_de_du_an_lien_quan',
                    themeKey: 'tieu_de_section',
                  }}
                />
                <ServiceCardList entities={relatedServices.entities} />
              </div>
            </section>
          ) : null}
          <div className="bg-neutral-100">
            {relatedPosts?.entities.length ? (
              <section className="py-10 lg:py-32">
                <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
                  <InfoItem
                    data={{
                      valueKey: 'tieu_de_tin_tuc_lien_quan',
                      themeKey: 'tieu_de_section',
                    }}
                  />
                  <PostCardList entities={relatedPosts.entities} />
                </div>
              </section>
            ) : null}
          </div>
        </SectionsOverride>
      ) : (
        <EntryNotFound />
      )}
    </>
  )
}
