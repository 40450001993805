import { markdownDefaultOverrides } from '@joehua/dew-components'
import slugify from '@sindresorhus/slugify'
import React, { FC, ReactNode } from 'react'

function makeId(children: ReactNode) {
  const tmp = typeof children === 'string' ? children : children?.[0]
  return slugify(tmp, { lowercase: true })
}

export interface TocItem {
  level: number
  id: string
  text: string
}

export function getToc(md: string) {
  return md.split('\n').reduce((acc: TocItem[], line) => {
    const tmp = /^(#{1,6})\s/.exec(line)
    if (!tmp) {
      return acc
    }
    const [, tmp1] = tmp
    const level = tmp1.length
    const text = line.replace(tmp1, '').trimLeft()
    return [...acc, { level, text, id: makeId(text) }]
  }, [])
}

const H1: FC = ({ children: children, ...rest }) => {
  return (
    <h1 {...rest} id={makeId(children)}>
      {children}
    </h1>
  )
}

const H2: FC = ({ children: children, ...rest }) => {
  return (
    <h2 {...rest} id={makeId(children)}>
      {children}
    </h2>
  )
}

const H3: FC = ({ children: children, ...rest }) => {
  return (
    <h3 {...rest} id={makeId(children)}>
      {children}
    </h3>
  )
}

const H4: FC = ({ children: children, ...rest }) => {
  return (
    <h4 {...rest} id={makeId(children)}>
      {children}
    </h4>
  )
}

const H5: FC = ({ children: children, ...rest }) => {
  return (
    <h5 {...rest} id={makeId(children)}>
      {children}
    </h5>
  )
}

const H6: FC = ({ children: children, ...rest }) => {
  return (
    <h6 {...rest} id={makeId(children)}>
      {children}
    </h6>
  )
}

export const markdownOverrides = {
  ...markdownDefaultOverrides,
  h1: { component: H1 },
  h2: { component: H2 },
  h3: { component: H3 },
  h4: { component: H4 },
  h5: { component: H5 },
  h6: { component: H6 },
}
