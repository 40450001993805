import { useWebsite } from '@joehua/dew-components'
import { LinkValue, RouterLinkProps, useRouter } from 'dew-router'
import React, { MouseEvent, useCallback } from 'react'
import { useClient } from 'urql'

import { queriesMap } from '../../queries'
import { variablesGetter } from '../../urql/variablesGetter'

export interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  to?: LinkValue
}

export function Link({ to, onClick: _onClick, ...rest }: LinkProps) {
  const websiteData = useWebsite()
  const { parseLink, navigator, routes } = useRouter()
  const client = useClient()
  const href = (to && parseLink(to)) || '#'

  const prefetch = useCallback(async () => {
    if (!to) {
      return
    }
    const page = routes.find((x) => x.name === to.name)?.page
    if (!page) {
      return
    }
    const query = queriesMap[page]
    const variables = variablesGetter[page]?.(to, websiteData)
    if (!query) {
      return
    }
    return client.query(query, variables).toPromise()
  }, [client, routes, to, websiteData])

  const onClick = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>) => {
      if (e.metaKey) {
        return
      }
      e.preventDefault()
      if (_onClick) {
        _onClick(e)
      }
      await prefetch()
      navigator.navigate(href)
    },
    [prefetch, _onClick, navigator, href],
  )

  return <a {...rest} onClick={onClick} onMouseOver={prefetch} href={href} />
}
