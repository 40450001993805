import {
  Block,
  BlockView,
  Form,
  ImageItem,
  InfoItem,
  Markdown,
} from '@joehua/dew-components'
import React, { Fragment, useMemo } from 'react'

import { BodyType, ServiceContentFragment } from '../../generated/graphql'
import { getBreadcrumbsByTaxon } from '../../utils'
import { Breadcrumbs } from '../Breadcrumbs'
import { Link } from '../Link'
import { getToc, markdownOverrides } from '../markdownOverrides'
import { Toc } from '../Toc'

export function ServiceContent({
  entity,
  block,
}: {
  entity: ServiceContentFragment
  block?: Block
}) {
  const toc = useMemo(() => getToc(entity.md), [entity.md])
  const [category] = entity?.categories || []

  const breadcrumbs = entity && [
    {
      to: { name: 'index', lang: 'vi' },
      title: 'Trang chủ',
    },
    {
      to: { name: 'services', lang: 'vi' },
      title: 'Dịch vụ',
    },
    ...getBreadcrumbsByTaxon({ item: category, taxonomyCode: 'project_category' }),
    {
      title: entity?.title,
    },
  ]
  return (
    <div>
      {entity.bodyType === BodyType.Block && block ? (
        <BlockView block={block} />
      ) : (
        <Fragment>
          <ImageItem
            data={{
              value: {
                image: { id: entity.image?.id, alt: entity.title },
                infoItem: {
                  title: entity.title,
                  subtitle: entity?.subtitle,
                  pretitle: entity?.pretitle,
                },
              },
              themeKey: 'hero',
              config: {
                infoItem: {
                  pretitleAs: 'h3',
                  titleAs: 'h1',
                  subtitleAs: 'h2',
                },
                useBackground: true,
                image: { transform: 'w_1200' },
              },
            }}
          />
          <div className="max-w-2xl lg:max-w-screen-xl mx-auto flex flex-wrap w-full md:py-6 px-3">
            <div className=" w-full lg:w-3/4 lg:pr-10">
              <div className="my-4">
                {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
              </div>
              <div
                id="toc"
                className="bg-neutral-100 rounded-lg p-4 max-w-md border border-neutral-200 mt-4"
              >
                <div className="text-sm uppercase text-neutral-600">Nội dung</div>
                <Toc items={toc} />
              </div>
              <div className="rich-text">
                {entity.md.length > 0 ? (
                  <Markdown
                    data={{ value: { md: entity.md } }}
                    overrides={markdownOverrides}
                  />
                ) : (
                  <div className="my-12">Đang cập nhật</div>
                )}
              </div>
              <aside className="-mx-2 my-6">
                {entity.tags.map((x) => (
                  <Link
                    to={{ name: 'tag', lang: 'vi', params: { slug: x.slug } }}
                    key={x.id}
                    className="m-1 px-2 py-1 rounded-sm text-sm text-accent-800 bg-accent-100 hover:bg-accent-200 transition ease-in-out duration-75 inline-block"
                  >
                    {x.title}
                  </Link>
                ))}
              </aside>
            </div>

            {entity.md.length > 0 ? (
              <div className="w-full lg:w-1/4 -mt-12">
                <div className="sticky top-0 pt-20 pb-4 md:max-w-lg mx-auto">
                  <div className="rounded-md border border-neutral-200 rounded-b-none bg-neutral-100">
                    <InfoItem
                      data={{
                        valueKey: 'sidebar_form__tieu_de',
                        theme: {
                          root: 'flex flex-wrap px-6 pt-6',
                          pretitle:
                            'uppercase text-sm font-sans font-bold text-neutral-800',
                          title: 'w-full text-3xl font-serif uppercase font-bold mb-3',
                          description: '',
                        },
                      }}
                    />
                    <Form
                      data={{
                        valueKey: 'form_dang_ky_dich_vu',
                        themeKey: 'form_mac_dinh',
                      }}
                    />
                    <ImageItem
                      data={{
                        valueKey: 'chuyen_vien',
                        theme: {
                          root:
                            'flex border border-neutral-200 p-3 rounded-md rounded-t-none border-t-0 bg-neutral-200',
                          imageWrapper: 'w-20 h-20 flex-none',
                          image: 'rounded-full',
                          infoItemWrapper: 'pl-3',
                          infoItem: {
                            pretitle: 'uppercase text-xs text-neutral-800',
                            title: 'text-base font-semibold text-neutral-700 block',
                            description: 'hidden block text-xs leading-relaxed',
                            nav: {
                              root: 'w-full flex flex-wrap mt-1',
                              itemWrapper: 'p-1',
                              item: {
                                root:
                                  'inline-flex items-center justify-center text-xs text-accent-700 border-b border-accent-700 border-dotted',
                                leftIcon: 'w-3 h-3 mr-1',
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      )}
      <ImageItem
        data={{
          valueKey: 'chuyen_vien_mobile',
          theme: {
            root:
              'lg:hidden flex border border-neutral-200 p-2 md:rounded-md border-t bg-neutral-100 fixed bottom-0 w-full md:w-1/2 md:m-1 z-30',
            imageWrapper: 'w-12 h-12 flex-none',
            image: 'rounded-full',
            infoItemWrapper: 'pl-3',
            infoItem: {
              root: 'flex flex-wrap items-baseline ',
              pretitle: 'text-xs text-neutral-800 mr-1 italic',
              title: 'text-base font-semibold text-neutral-700 block',
              description: 'hidden block text-xs leading-relaxed',
              nav: {
                root: 'w-full flex flex-wrap mt-1',
                itemWrapper: 'mr-2',
                item: {
                  root:
                    'inline-flex items-center justify-center text-xs text-accent-700 border-b border-accent-700 border-dotted',
                  leftIcon: 'w-3 h-3 mr-1',
                },
              },
            },
          },
        }}
      />
    </div>
  )
}
