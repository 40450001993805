import { ImageItem } from '@joehua/dew-components'
import React, { Fragment } from 'react'

export function EntryNotFound() {
  return (
    <Fragment>
      <ImageItem
        data={{
          valueKey: 'not_found',
          config: {
            useBackground: true,
          },
          themeKey: 'not_found',
        }}
      />
    </Fragment>
  )
}
