import {
  Form,
  GoogleMap,
  GoogleMapValue,
  ImageItem,
  ImageItemList,
  InfoItem,
  SectionsOverride,
  useWebsite,
} from '@joehua/dew-components'
import React, { Fragment } from 'react'

import { Seo } from '../../components/Seo'
import { ContactPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'
import { NotFoundPage } from '../NotFound'

export function ContactPage() {
  const [{ data }] = useRouteQuery<ContactPageQuery>('Contact')
  const { website } = useWebsite()
  const route = data?.routeBy
  const contact = website.sectionValues.Form?.contact
  const seo = data?.routeBy?.seo

  const map: GoogleMapValue = route?.sectionValues['map']

  if (!route) {
    return (
      <Fragment>
        <NotFoundPage />
      </Fragment>
    )
  }
  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <ImageItem
        data={{
          valueKey: 'hero',
          themeKey: 'hero',
          config: {
            infoItem: {
              pretitleAs: 'h3',
              titleAs: 'h1',
              subtitleAs: 'h2',
            },
            useBackground: true,
            image: { transform: 'w_1200' },
          },
        }}
      />

      <div className="flex flex-wrap w-full mx-auto items-center justify-center max-w-screen-xl lg:py-10">
        {seo && <Seo seo={seo} />}
        <div className="w-full my-4 lg:my-6">
          <div className="w-full flex flex-wrap px-3 items-start max-w-5xl mx-auto">
            <div className="md:w-1/2 md:pr-10">
              <ImageItemList
                data={{
                  valueKey: 'cua_hang',
                  theme: {
                    item: {
                      imageWrapper: 'rounded-md',
                      image: 'rounded-md',
                      infoItemWrapper: 'mt-4',
                      infoItem: {
                        root: 'w-full',
                        title: 'uppercase font-semibold block',
                        labelValueList: {
                          root: 'w-full mt-4',
                          itemWrapper: 'mb-2',
                          item: {
                            root: 'flex w-full items-center',
                            iconWrapper:
                              'bg-neutral-200 flex-none mr-1 rounded-full inline-flex items-center justify-center w-6 h-6',
                            icon: 'w-4 h-4 text-neutral-700',
                            contentWrapper:
                              'flex-auto items-center text-neutral-700 mt-1',
                            label: 'font-semibold mr-1',
                            value: '',
                          },
                        },
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="md:w-1/2 bg-neutral-100 p-4 md:p-6 lg:p-8 w-full mt-6 md:mt-0">
              <InfoItem
                data={{
                  valueKey: 'tieu_de_form',
                  theme: {
                    root: 'px-6',
                    title: 'uppercase font-semibold block text-center',
                  },
                }}
              />
              <Form
                data={{
                  valueKey: 'form_lien_he_chinh',
                  themeKey: 'form_mac_dinh',
                }}
              />
            </div>
          </div>
        </div>

        <div className="ar-4/3 md:ar-21/9 relative w-full mt-10">
          <GoogleMap
            data={{
              valueKey: 'map',
              theme: {
                root: 'absolute inset-0 w-full h-full',
              },
            }}
          />
        </div>
      </div>
    </SectionsOverride>
  )
}
