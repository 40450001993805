import { useWebsite } from '@joehua/dew-components'
import { useRouter, useUrlParams } from 'dew-router'
import { useQuery } from 'urql'

import { queriesMap } from '../queries'
import { variablesGetter } from './variablesGetter'

export function useRouteQuery<T, V = undefined>(page: string) {
  const websiteData = useWebsite()
  const { query, matchedRoutes } = useRouter()
  const routeName = matchedRoutes[0]?.name
  const params = useUrlParams()
  const getVariables = variablesGetter[page]
  const variables = getVariables?.(
    { name: routeName, lang: 'vi', params, query },
    websiteData,
  )
  return useQuery<T, V>({ query: queriesMap[page], variables })
}
