import BasicContent from './fragments/BasicContent.gql'
import Breadcrumb from './fragments/Breadcrumb.gql'
import EntryCard from './fragments/EntryCard.gql'
import Paging from './fragments/Paging.gql'
import PostCard from './fragments/PostCard.gql'
import PostContent from './fragments/PostContent.gql'
import Seo from './fragments/Seo.gql'
import ServiceCard from './fragments/ServiceCard.gql'
import ServiceContent from './fragments/ServiceContent.gql'
import TaxonBreadcrumbItem from './fragments/TaxonBreadcrumbItem.gql'
import TaxonItem from './fragments/TaxonItem.gql'
import createSubscriber from './mutations/createSubscriber.gql'
import Basic from './queries/Basic.gql'
import Contact from './queries/Contact.gql'
import Index from './queries/Index.gql'
import Post from './queries/Post.gql'
import PostCategory from './queries/PostCategory.gql'
import Posts from './queries/Posts.gql'
import Service from './queries/Service.gql'
import ServiceCategory from './queries/ServiceCategory.gql'
import Services from './queries/Services.gql'
import Tag from './queries/Tag.gql'

const fragmentsMap: { [key: string]: string } = {
  PostContent,
  BasicContent,
  ServiceContent,
  PostCard,
  ServiceCard,
  EntryCard,
  TaxonBreadcrumbItem,
  TaxonItem,
  Paging,
  Seo,
  Breadcrumb,
}

const fragmentKeys = Object.keys(fragmentsMap).map((key) => ({
  key,
  replace: fragmentsMap[key],
}))

function injectFragments(input: Record<string, string>) {
  const keys = Object.keys(input)
  return keys.reduce((acc: Record<string, string>, key) => {
    let tmp = input[key]
    fragmentKeys.forEach(({ key, replace }) => {
      if (tmp.includes(`...${key}`)) {
        tmp = `${replace}\n${tmp}`
      }
    })

    return {
      ...acc,
      [key]: tmp,
    }
  }, {})
}

const rawQueriesMap = {
  Index,
  Post,
  PostCategory,
  Posts,
  Service,
  ServiceCategory,
  Services,
  Tag,
  Contact,
  Basic,
}

const rawMutationsMap = {
  createSubscriber,
}

export const queriesMap = injectFragments(rawQueriesMap) as typeof rawQueriesMap
export const mutationsMap = injectFragments(rawMutationsMap) as typeof rawMutationsMap
