import { Nav } from '@joehua/dew-components'
import React, { Fragment, ReactNode, useState } from 'react'

import { BackToTop } from '../BackToTop'
import { DesktopNav } from '../DesktopNav'
import { Footer } from '../Footer'
import { MobileDrawer } from '../MobileDrawer'
import { MobileNav } from '../MobileNav'

export function Layout({ children }: { children: ReactNode }) {
  const [isShowDrawer, setIsShowDrawer] = useState(false)
  return (
    <Fragment>
      <DesktopNav />
      <MobileNav isShowDrawer={isShowDrawer} setIsShowDrawer={setIsShowDrawer} />
      <MobileDrawer isShow={isShowDrawer} onClose={() => setIsShowDrawer(false)} />
      <div className="">
        {children}
        <Footer />
        <BackToTop />
      </div>

      <Nav
        data={{
          valueKey: 'contact_chinh',
          theme: {
            root: 'lg:hidden fixed bottom-0 z-10 m-2',
            item: {
              leftIcon: 'w-4 h-4 mr-1 text-accent-700',
              root:
                'h-10 lg:w-auto px-2 uppercase bg-accent-100 shadow-md font-sans inline-flex text-accent-700 inline-flex items-center justify-center rounded-md text-sm',
              text: 'text-accent-700',
            },
          },
        }}
      />
    </Fragment>
  )
}
