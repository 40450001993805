import { Icon } from '@joehua/dew-components'
import { LinkValue } from 'dew-router'
import React, { Fragment, ReactNode } from 'react'

import { Link } from '../../components/Link'

export function Breadcrumbs({
  seperator = (
    <span className="mx-1">
      <Icon name="chevron-right" className="w-4 h-4 inline-flex" />
    </span>
  ),
  items,
}: {
  seperator?: ReactNode
  items: { to?: LinkValue; title: string }[]
}) {
  return (
    <div className="text-neutral-600 text-sm">
      {items.map((x, index) => {
        if (!x.to) {
          return (
            <Fragment key={index}>
              <span className="cursor-not-allowed text-neutral-400">{x.title}</span>
              {index < items.length - 1 && (
                <span className="mx-2">
                  <Icon name="chevron-right" className="w-4 h-4 inline-flex" />
                </span>
              )}
            </Fragment>
          )
        }
        return (
          <Fragment key={index}>
            <Link
              to={x.to}
              className="hover:text-accent-600 transition ease-in duration-75 text-sm"
              title={x.title}
            >
              {x.title}
            </Link>
            {index < items.length - 1 && seperator}
          </Fragment>
        )
      })}
    </div>
  )
}
