import { InfoItem, SectionsOverride } from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { Seo } from '../../components/Seo'
import { ServiceCardList } from '../../components/ServiceCardList'
import { ServiceCategoryPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function ServiceCategoryPage() {
  const [{ data, fetching }] = useRouteQuery<ServiceCategoryPageQuery>('ServiceCategory')
  const { projects, route, category } = data ?? {}

  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <section className="max-w-screen-xl mx-auto py-10 lg:py-20 px-3">
        {fetching && <Loading />}
        {category && (
          <>
            <Seo seo={category.seo} />
          </>
        )}
        {projects?.entities.length ? (
          <section>
            <InfoItem
              data={{
                value: {
                  title: category?.title || 'Dự án',
                  description: category?.description || '',
                },
                theme: {
                  root: 'mb-6',
                  pretitle: 'uppercase font-body tracking-wide font-body',
                  title: 'text-3xl lg:text-4xl w-full font-serif block',
                  description: 'rich-text block',
                },
                config: {
                  titleAs: 'h1',
                  descriptionAs: 'h2',
                },
              }}
            />
            <ServiceCardList entities={projects?.entities} />
          </section>
        ) : null}
      </section>
    </SectionsOverride>
  )
}
