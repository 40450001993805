import {
  Carousel,
  CountDown,
  // CountUp,
  FaqList,
  Form,
  Gallery,
  GoogleMap,
  Header,
  Icon,
  Image,
  ImageItem,
  ImageItemCarousel,
  ImageItemList,
  InfoItem,
  LabelValueList,
  Markdown,
  Nav,
  PersonItem,
  PersonItemList,
  ScrollActionTrigger,
  SectionValueType,
  SimpleDataTable,
  TimerActionTrigger,
} from '@joehua/dew-components'
import { FC } from 'react'

import { VideoPlayer } from './VideoPlayer'

export const sectionComponents: Record<SectionValueType, FC<any>> = {
  Image: Image,
  ImageItem: ImageItem,
  InfoItem: InfoItem,
  Nav: Nav,
  LabelValueList: LabelValueList,
  Carousel: Carousel,
  Gallery: Gallery,
  FaqList: FaqList,
  Form: Form,
  Header: Header,
  GoogleMap: GoogleMap,
  Markdown: Markdown,
  SimpleDataTable: SimpleDataTable,
  ImageItemList: ImageItemList,
  ImageItemCarousel: ImageItemCarousel,
  // CountUp: CountUp,
  CountDown: CountDown,
  Icon: Icon,
  PersonItem: PersonItem,
  PersonItemList: PersonItemList,
  ScrollActionTrigger: ScrollActionTrigger,
  TimerActionTrigger: TimerActionTrigger,
  VideoPlayer: VideoPlayer,
}
