import { LinkValue } from 'dew-router'
import { WebsiteData } from 'dew-types'

import {
  PostCategoryPageQueryVariables,
  ServiceCategoryPageQueryVariables,
  TagPageQueryVariables,
} from '../generated/graphql'

export type VariablesGetterFn = (
  { params, query, name }: LinkValue,
  websiteData: WebsiteData,
) => any

export interface VariablesGetter {
  [key: string]: VariablesGetterFn
}

function getIntFromUrlQuery(v?: string | string[], defaultValue?: number) {
  if (!v) {
    return defaultValue
  }
  return parseInt(v as string, 10)
}

export const variablesGetter: VariablesGetter = {
  Index: ({ lang }, _websiteData) => ({
    lang: lang || 'vi',
  }),
  Post: ({ lang, params }) => ({
    lang: lang || 'vi',
    slug: params?.slug as string,
  }),
  Basic: ({ lang, params }) => ({
    lang: lang || 'vi',
    slug: params?.slug as string,
  }),
  PostCategory: ({ lang, params, query }) => {
    const variables: PostCategoryPageQueryVariables = {
      lang: lang || 'vi',
      slug: params?.slug as string,
      paging: { page: getIntFromUrlQuery(query?.page) },
    }
    return variables
  },
  Tag: ({ lang, params, query }) => {
    const variables: TagPageQueryVariables = {
      lang: lang || 'vi',
      slug: params?.slug as string,
      paging: { page: getIntFromUrlQuery(query?.page) },
    }
    return variables
  },
  Posts: ({ lang, query }) => ({
    lang: lang || 'vi',
    paging: { page: getIntFromUrlQuery(query?.page) },
  }),
  Service: ({ lang, params }) => ({
    lang: lang || 'vi',
    slug: params?.slug as string,
  }),
  ServiceCategory: ({ lang, params, query }) => {
    const variables: ServiceCategoryPageQueryVariables = {
      lang: lang || 'vi',
      slug: params?.slug as string,
      paging: { page: getIntFromUrlQuery(query?.page) },
    }
    return variables
  },
  Services: ({ lang, query }) => ({
    lang: lang || 'vi',
    paging: { page: getIntFromUrlQuery(query?.page), pageSize: 60 },
  }),
}
