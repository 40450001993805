import { Block, ImageItem, Markdown } from '@joehua/dew-components'
import df from 'dateformat'
import React, { Fragment, useMemo } from 'react'

import { getToc, markdownOverrides } from '../../components/markdownOverrides'
import { Toc } from '../../components/Toc'
import { PostContentFragment } from '../../generated/graphql'
import { getBreadcrumbsByTaxon } from '../../utils'
import { Breadcrumbs } from '../Breadcrumbs'
import { Link } from '../Link'
import { SocialShare } from '../SocialShare'

export function PostContent({ entity }: { entity: PostContentFragment; block?: Block }) {
  const toc = useMemo(() => getToc(entity.md), [entity.md])

  const [category] = entity?.categories || []
  const breadcrumbs = entity && [
    {
      to: { name: 'index', lang: 'vi' },
      title: 'Trang chủ',
    },
    {
      to: { name: 'posts', lang: 'vi' },
      title: 'Bài viết',
    },
    ...getBreadcrumbsByTaxon({ item: category, taxonomyCode: 'post_category' }),
    {
      title: entity?.title,
    },
  ]

  return (
    <Fragment>
      <ImageItem
        data={{
          value: {
            image: { id: entity.image?.id, alt: entity.title },
            infoItem: {
              title: entity.title,
              subtitle: entity?.subtitle,
              pretitle: entity?.pretitle,
            },
          },
          themeKey: 'hero',
          config: {
            infoItem: {
              pretitleAs: 'h3',
              titleAs: 'h1',
              subtitleAs: 'h2',
            },
            useBackground: true,
            image: { transform: 'w_1200' },
          },
        }}
      />
      <div className="max-w-2xl lg:max-w-screen-xl mx-auto flex flex-wrap w-full md:py-6 px-3">
        <div className="py-4">{breadcrumbs && <Breadcrumbs items={breadcrumbs} />}</div>

        {/* <h1 className="w-full lg:w-3/4 lg:pr-10 text-3xl lg:text-4xl font-serif font-bold text-neutral-800 leading-snug">
          {entity.title}
        </h1> */}

        <div className="w-full lg:w-3/4 lg:pr-10 py-4">
          {entity.md.length > 0 ? (
            <div
              id="toc"
              className="bg-neutral-100 rounded-lg p-4 max-w-md border border-neutral-200 mt-4"
            >
              <div className="text-sm uppercase text-neutral-600">Nội dung</div>
              <Toc items={toc} />
            </div>
          ) : null}
          <div className="rich-text">
            {entity.md.length > 0 ? (
              <Markdown
                data={{ value: { md: entity.md } }}
                overrides={markdownOverrides}
              />
            ) : (
              <div>Đang cập nhật</div>
            )}
          </div>
          <aside className="-mx-2 my-6">
            {entity.tags.map((x) => (
              <Link
                to={{ name: 'tag', lang: 'vi', params: { slug: x.slug } }}
                key={x.id}
                className="m-1 px-2 py-1 rounded-sm text-sm text-accent-800 bg-accent-100 hover:bg-accent-200 transition ease-in-out duration-75 inline-block"
              >
                {x.title}
              </Link>
            ))}
          </aside>
          <div className="flex justify-between text-xs font-medium text-neutral-600 my-6 w-full">
            <div className="">{df(new Date(entity.publishedAt), 'dd-mm-yyyy')}</div>
            <SocialShare url={entity.permanentLink} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
