import { Image, Nav, useWebsite } from '@joehua/dew-components'
import React from 'react'

export function DesktopNav() {
  const {
    website: { sectionValues },
  } = useWebsite()
  const logo = sectionValues?.Image?.logo
  return (
    <header className="hidden lg:flex bg-transparent fixed top-0 w-full z-30 bg-gradient-to-b from-black via-alpha-30 to-transparent">
      <nav className="w-full max-w-screen-xl mx-auto flex justify-end items-center h-16 px-3 relative">
        <div className="absolute top-0 left-0">
          <Image
            className="h-16"
            data={{
              value: {
                ...logo,
                wrapperNavItem: { type: 'link', to: { name: 'index', lang: 'vi' } },
              },
              theme: { root: 'w-64 inline-flex' },
            }}
          />
        </div>
        <div className="flex items-center">
          <Nav
            data={{
              valueKey: 'menu_chinh',
              theme: {
                root: 'flex items-center',
                itemWrapper: 'mx-6',
                item: { root: 'uppercase font-sans uppercase text-neutral-100' },
              },
            }}
          />

          {/* <Nav
            data={{
              valueKey: 'contact_chinh',
              themeKey: 'contact_menu_desktop',
            }}
          /> */}
        </div>
      </nav>
    </header>
  )
}
