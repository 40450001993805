import 'react/jsx-runtime'

import AOS from 'aos'
import { BrowserRouter } from 'dew-router'
import { WebsiteProcessor } from 'dew-ssr'
import { AppDataType, WebsiteData } from 'dew-types'
import React, { FC } from 'react'
import { hydrate, render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'urql'

import { getEndpoint } from './config'
import { createSsr, initializeClient } from './urql/client'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    __DATA__?: WebsiteData
    ENDPOINT?: string
  }
}

export async function bootstrap(App: FC<{ websiteData: WebsiteData }>) {
  const rootEle = document.getElementById('root')

  if (!rootEle) {
    return null
  }

  const domain = WebsiteProcessor.getDomain(window.location.hostname)
  const path = window.location.pathname

  let websiteData: WebsiteData | undefined = window.__DATA__
  const isClient = !websiteData

  const endpoint = getEndpoint()
  if (!websiteData) {
    const websiteOrRedirect = await WebsiteProcessor.getWebsiteOrRedirect(
      endpoint,
      domain,
      path,
    )
    if (websiteOrRedirect.type === AppDataType.Redirect) {
      window.location.replace(websiteOrRedirect.redirect.to)
      return
    }
    websiteData = websiteOrRedirect
  }

  const ssr = createSsr()
  const client = initializeClient({
    endpoint: endpoint,
    domain: WebsiteProcessor.getDomain(window.location.hostname),
    ssr,
  })

  const node = (
    <HelmetProvider>
      <Provider value={client}>
        <BrowserRouter routes={websiteData.routes}>
          <App websiteData={websiteData} />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  )

  if (isClient) {
    return render(node, rootEle)
  } else {
    hydrate(node, rootEle)
  }

  import('lazysizes')
  AOS.init()
}
