import { Icon } from '@joehua/dew-components'
import React, { useEffect, useState } from 'react'

export function BackToTop({ offset = 700 }: { offset?: number }) {
  const [isShow, setIsShow] = useState(() => {
    if (typeof window === 'undefined') {
      return false
    }
    return window.scrollY > offset ? true : false
  })

  useEffect(() => {
    const handler = () => {
      setIsShow(window.scrollY > offset ? true : false)
    }
    window.addEventListener('scroll', handler, { passive: true })
    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [offset])

  if (!isShow) {
    return null
  }

  return (
    <div
      className="fixed bottom-0 right-0"
      onClick={() => {
        document.body.scrollTop = 360 // For Safari
        document.documentElement.scrollTop = 360 // For Chrome, Firefox, IE and Opera
      }}
    >
      <button
        className="bg-accent-100 p-2 rounded-full shadow-md m-2 cursor-pointer focus:outline-none active:bg-accent-200"
        title="Back to toc"
      >
        <Icon name="arrow-up" className="w-6 h-6 text-accent-600" />
      </button>
    </div>
  )
}
