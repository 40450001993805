import {
  makeColorVariables,
  SubscriberValue,
  TimerActionTrigger,
  WebsiteProvider,
} from '@joehua/dew-components'
import { Route } from 'dew-router'
import { WebsiteData } from 'dew-types'
import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMutation } from 'urql'

import { Layout } from './components/Layout'
import { Link } from './components/Link'
import {
  CreateSubscriberMutation,
  CreateSubscriberMutationVariables,
} from './generated/graphql'
import { BasicPage } from './pages/Basic'
import { ContactPage } from './pages/Contact'
import { IndexPage } from './pages/Index'
import { NotFoundPage } from './pages/NotFound'
import { PostPage } from './pages/Post'
import { PostCategoryPage } from './pages/PostCategory'
import { PostsPage } from './pages/Posts'
import { ServicePage } from './pages/Service'
import { ServiceCategoryPage } from './pages/ServiceCategory'
import { ServicesPage } from './pages/Services'
import { TagPage } from './pages/Tag'
import { mutationsMap } from './queries'
import { sectionComponents } from './sectionComponents'
import { addStyle, getFieldValues, makeFontVariables } from './utils'

const pages: { [key: string]: typeof IndexPage } = {
  Index: IndexPage,
  NotFound: NotFoundPage,
  Post: PostPage,
  Basic: BasicPage,
  PostCategory: PostCategoryPage,
  Tag: TagPage,
  Posts: PostsPage,
  Service: ServicePage,
  ServiceCategory: ServiceCategoryPage,
  Services: ServicesPage,
  Contact: ContactPage,
}

export function App({ websiteData }: { websiteData: WebsiteData }) {
  const {
    website: { fieldValues },
    routes,
  } = websiteData
  const [, submitCreateSubscriber] = useMutation<
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables
  >(mutationsMap.createSubscriber)

  const thankYouMessage = getFieldValues<string>(
    fieldValues,
    'thank_you_message',
    'Cám ơn bạn đã đăng ký! Chúng tôi sẽ liên hệ với bạn',
  )
  const fonts = getFieldValues<Record<string, any>>(fieldValues, 'fonts')
  const colorPallete = getFieldValues<Record<string, any>>(fieldValues, 'color_palette')
  const cssLinks = getFieldValues(fieldValues, 'css_links', [])
  const lazyCssLinks = getFieldValues(fieldValues, 'lazy_css_links', [])

  useEffect(() => {
    lazyCssLinks?.forEach(addStyle)
  }, [lazyCssLinks])

  useEffect(() => {
    lazyCssLinks?.forEach(addStyle)
  }, [lazyCssLinks])

  const createSubscriber = useCallback(
    async (v: SubscriberValue, formCode: string) => {
      await submitCreateSubscriber({
        data: {
          ...v,
          formCode,
        },
      })
      alert(thankYouMessage)
    },
    [submitCreateSubscriber, thankYouMessage],
  )

  return (
    <WebsiteProvider
      components={{ Link }}
      websiteData={websiteData}
      sectionComponents={sectionComponents}
      createSubscriber={createSubscriber}
    >
      <Helmet>
        {cssLinks?.map((link, idx) => (
          <link key={idx} href={link} rel="stylesheet" />
        ))}
        {colorPallete && <style>{makeColorVariables(colorPallete)}</style>}
        {fonts && <style>{makeFontVariables(fonts)}</style>}
      </Helmet>
      <Layout>
        {routes.map((route) => {
          const Component = pages[route.page]
          const key = `${route.name}:${route.lang}`

          if (!Component) {
            return null
          }
          return (
            <Route key={key} lang={route.lang} name={route.name}>
              <Component />
            </Route>
          )
        })}
      </Layout>
      <TimerActionTrigger
        data={{ value: { action: 'popup', interval: 10000, timeout: 3_600_000 } }}
      />
    </WebsiteProvider>
  )
}
