import { AppPortal, InfoItem, SectionsOverride } from '@joehua/dew-components'
import { mergeDeepRight } from 'ramda'
import React, { Fragment } from 'react'

import { EntryNotFound } from '../../components/EntryNotFound'
import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { Seo } from '../../components/Seo'
import { ServiceCardList } from '../../components/ServiceCardList'
import { ServiceContent } from '../../components/ServiceContent'
import { ServicePageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function ServicePage() {
  const [{ data, fetching }] = useRouteQuery<ServicePageQuery>('Service')

  const { route, service, relatedPosts, relatedServices } = data ?? {}

  return (
    <Fragment>
      {fetching && <Loading />}
      {service ? (
        <SectionsOverride
          sectionConfigs={mergeDeepRight(
            { LabelValueList: { default: { useMarkdown: true } } },
            route?.sectionConfigs,
          )}
          sectionThemes={mergeDeepRight(
            {
              VideoPlayer: {
                default: { root: 'relative ar-16/9 my-4', video: 'absolute inset-0' },
              },
            },
            route?.sectionThemes,
          )}
          sectionValues={mergeDeepRight(route?.sectionValues, service.sectionValues)}
        >
          <Seo seo={service.seo} />
          <ServiceContent entity={service} />
          {/* <AppPortal
            data={{
              name: 'popup',
              config: { closeButton: { icon: 'x' } },
              theme: {
                backdrop:
                  'fixed inset-0 h-screen w-full bg-black bg-opacity-70 z-40 overflow-y-auto',
                portal: 'w-full md:w-4/5 mx-auto my-8',
                content: 'w-full',
                closeButton: {
                  root: 'absolute right-0 text-accent-900 z-50 m-4',
                  icon: 'w-8 h-8',
                },
              },
              block: {
                sections: [
                  {
                    key: 'form_header',
                    component: 'InfoItem',
                    data: {
                      valueKey: 'popup_form__tieu_de',
                      theme: {
                        root: 'flex flex-col px-4 md:px-6 pt-6 text-center',
                        pretitle:
                          'w-full uppercase text-sm font-sans font-bold text-neutral-800 text-center',
                        title: 'w-full text-xl font-serif text-center my-1',
                        subtitle: 'text-center',
                      },
                    },
                  },
                  {
                    key: 'form',
                    component: 'Form',
                    data: { valueKey: 'form_dang_ky_du_an', themeKey: 'form_mac_dinh' },
                  },
                ],
                root: {
                  key: 'root',
                  type: 'container',
                  theme: {
                    root: 'bg-accent-100 p-6 lg:p-10 rounded-md max-w-xl mx-auto w-full',
                    inner: 'max-w-lg mx-auto',
                  },
                  items: [
                    {
                      key: 'form_header',
                      type: 'element',
                    },
                    {
                      key: 'form',
                      type: 'element',
                    },
                  ],
                },
              },
            }}
          /> */}

          {relatedServices?.entities.length ? (
            <section className="py-10 lg:py-32 bg-neutral-100">
              <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
                <InfoItem
                  data={{
                    valueKey: 'tieu_de_dich_vu_lien_quan',
                    themeKey: 'tieu_de_section',
                  }}
                />
                <ServiceCardList entities={relatedServices.entities} />
              </div>
            </section>
          ) : null}

          {relatedPosts?.entities.length ? (
            <section className="py-10 lg:py-32">
              <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
                <InfoItem
                  data={{
                    valueKey: 'tieu_de_tin_tuc_lien_quan',
                    themeKey: 'tieu_de_section',
                  }}
                />
                <PostCardList entities={relatedPosts.entities} />
              </div>
            </section>
          ) : null}
        </SectionsOverride>
      ) : (
        <EntryNotFound />
      )}

      {/* <AppPortal
        data={{
          name: 'popup',
          config: { closeButton: { icon: 'x' } },
          theme: {
            backdrop:
              'fixed inset-0 h-screen w-full bg-black bg-opacity-70 z-40 overflow-y-auto',
            portal: 'w-4/5 mx-auto my-8',
            content: 'w-full',
            closeButton: {
              root: 'absolute right-0 text-white z-50 m-4',
              icon: 'w-8 h-8',
            },
          },
          block: {
            sections: [
              {
                key: 'form_header',
                component: 'InfoItem',
                data: {
                  valueKey: 'form_popup_tieu_de',
                  themeKey: 'tieu_de_form',
                },
              },
              {
                key: 'form',
                component: 'Form',
                data: { valueKey: 'form_dang_ky_du_an', themeKey: 'form_day_du' },
              },
            ],
            root: {
              key: 'root',
              type: 'container',
              theme: {
                root: 'bg-accent-200 p-6 lg:p-10 rounded-md max-w-xl mx-auto w-full',
                inner: 'max-w-lg mx-auto',
              },
              items: [
                {
                  key: 'form_header',
                  type: 'element',
                },
                {
                  key: 'form',
                  type: 'element',
                  theme: {
                    root: '',
                  },
                },
              ],
            },
          },
        }}
      /> */}
    </Fragment>
  )
}
