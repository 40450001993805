import { Icon, Image } from '@joehua/dew-components'
import df from 'dateformat'
import React from 'react'

import { PostCardFragment } from '../../generated/graphql'
import { Link } from '../Link'

export function PostCard({ entity }: { entity: PostCardFragment }) {
  return (
    <Link
      to={{ name: 'post', lang: 'vi', params: { slug: entity.slug } }}
      className="no-underline bg-white transition ease-in duration-200 flex flex-1 flex-col rounded-sm overflow-hidden h-full img-zoom-on-hover border border-neutral-100"
    >
      <div className="overflow-hidden w-full relative ar-16/9 mb-6">
        <Image
          className="absolute inset-0 rounded w-full h-full object-cover object-center block"
          data={{
            value: { id: entity.image?.id, alt: entity.title, title: entity.title },
            config: { transform: 'w_400,h_225', lazyload: false },
          }}
        />
        <div className="absolute absolute-fill flex justify-start items-start text-center p-2">
          {entity.featured && (
            <div className="bg-accent-600 inline-block px-2 py-1 uppercase text-neutral-100 rounded-sm font-bold text-xs">
              Nổi bật
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="px-5 ">
          <div className="flex justify-between text-xs font-medium text-neutral-600">
            {df(new Date(entity.publishedAt), 'dd-mm-yyyy')}
          </div>
          <h3 className="text-xl text-neutral-900 leading-relaxed mb-3 font-sans line-clamp line-clamp-2 mt-2 font-semibold">
            {entity.title}
          </h3>
          <p className="leading-relaxed text-neutral-800 line-clamp line-clamp-3">
            {entity.description}
          </p>
        </div>
        <div className="flex items-center flex-wrap p-5">
          <div className="text-accent-700 inline-flex items-center md:mb-2 lg:mb-0">
            Xem tiếp
            <Icon name="arrow-right" className="w-4 h-4 ml-1" />
          </div>
        </div>
      </div>
    </Link>
  )
}
