import { HtmlAnchorProps } from '@joehua/dew-components/dist/interfaces/elements'
import React, { useCallback, useMemo, useRef } from 'react'

import networks from './networks'

export interface SocialShareProps extends HtmlAnchorProps {
  network: keyof typeof networks
  url: string
  title: string
  description?: string
  quote?: string
  hashtags?: string
  twitterUser?: string
  media?: string
  popup?: { width: number; height: number }
}

function encodedHashtags(key: string, hashtags: string) {
  if (key === 'facebook' && hashtags.length) {
    return '%23' + hashtags.split(',')[0]
  }

  return hashtags
}

export function SocialShareItem({
  url,
  network,
  title,
  description = '',
  quote = '',
  hashtags = '',
  twitterUser = '',
  media = '',
  popup = {
    width: 626,
    height: 436,
  },
  children,
  ...rest
}: SocialShareProps) {
  const key = network.toLowerCase()
  const rawLink = useMemo(() => {
    const ua = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : ''
    /**
     * On IOS, SMS sharing link need a special formatting
     * Source: https://weblog.west-wind.com/posts/2013/Oct/09/Prefilling-an-SMS-on-Mobile-Devices-with-the-sms-Uri-Scheme#Body-only
     */
    if (key === 'sms' && (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)) {
      return networks[key].replace(':?', ':&')
    }

    return networks[key]
  }, [key])

  const shareLink = useMemo(() => {
    let link = rawLink

    /**
     * Twitter sharing shouldn't include empty parameter
     * Source: https://github.com/nicolasbeauvais/vue-social-sharing/issues/143
     */
    if (key === 'twitter') {
      if (!hashtags.length) link = link.replace('&hashtags=@h', '')
      if (!twitterUser.length) link = link.replace('@tu', '')
    }

    return link
      .replace(/@tu/g, '&via=' + encodeURIComponent(twitterUser))
      .replace(/@u/g, encodeURIComponent(url))
      .replace(/@t/g, encodeURIComponent(title))
      .replace(/@d/g, encodeURIComponent(description))
      .replace(/@q/g, encodeURIComponent(quote))
      .replace(/@h/g, encodedHashtags)
      .replace(/@m/g, encodeURIComponent(media))
  }, [description, hashtags.length, key, media, quote, rawLink, title, twitterUser, url])

  const popupWindow = useRef<Window | null>(null)

  const share = useCallback(() => {
    // If a popup window already exist, we close it and trigger a change event.
    if (popupWindow) {
      // Force close (for Facebook)
      popupWindow.current?.close()
    }

    const popupTop = 0
    const popupLeft = 0

    popupWindow.current = window.open(
      shareLink,
      [
        `sharer-${key}`,
        `height=${popup.height}`,
        `width=${popup.width}`,
        `left=${popupLeft}`,
        `top=${popupTop}`,
        `screenX=${popupLeft}`,
        `screenY=${popupTop}`,
      ].join('&'),
    )

    if (!popupWindow.current) return
    popupWindow.current.focus()
  }, [key, popup.height, popup.width, shareLink])

  return (
    <a {...rest} onClick={share}>
      {children}
    </a>
  )
}
