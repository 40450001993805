export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: any;
  /**
   * Represents an uploaded file.
   * 
   */
  Upload: any;
};

export type AddEntriesTaxonCommand = {
  entriesId: Array<Scalars['ID']>;
  taxonId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type AddEntryBlockCommand = {
  entryId: Scalars['ID'];
  name: Scalars['String'];
};

export type AddEntrySectionValueCommand = {
  code: Scalars['String'];
  entryId: Scalars['ID'];
  type: SectionValueType;
  value: Scalars['Json'];
};

export type AddEntryTaxonCommand = {
  entryId: Scalars['ID'];
  taxonId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type AddEntryTypeFieldCommand = {
  entryTypeId: Scalars['ID'];
  fieldId: Scalars['ID'];
};

export type AddEntryTypeTaxonomyCommand = {
  entryTypeId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type AddLayoutBlockCommand = {
  layoutId: Scalars['ID'];
};

export type AddLayoutFieldValueCommand = {
  code: Scalars['String'];
  layoutId: Scalars['ID'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type AddOrderItemCommand = {
  key?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  variantId: Scalars['ID'];
};

export type AddOrderItemsInput = {
  items: Array<OrderItemInput>;
  key?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type AddRouteBlockCommand = {
  routeId: Scalars['ID'];
};

export type AddRouteFieldValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type AddRouteSectionConfigCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionConfigType;
  value: Scalars['Json'];
};

export type AddRouteSectionThemeCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionThemeType;
  value: Scalars['Json'];
};

export type AddRouteSectionValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionValueType;
  value: Scalars['Json'];
};

export type AddWebsiteFieldValueCommand = {
  code: Scalars['String'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type AddWebsiteSectionConfigCommand = {
  code: Scalars['String'];
  type: SectionConfigType;
  value: Scalars['Json'];
};

export type AddWebsiteSectionThemeCommand = {
  code: Scalars['String'];
  type: SectionThemeType;
  value: Scalars['Json'];
};

export type AddWebsiteSectionValueCommand = {
  code: Scalars['String'];
  type: SectionValueType;
  value: Scalars['Json'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  province: Scalars['String'];
  region: Scalars['String'];
  regionCode: Scalars['String'];
  ward: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  district: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  province: Scalars['String'];
  region: Scalars['String'];
  regionCode: Scalars['String'];
  ward: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
};

export enum AppDataType {
  Redirect = 'REDIRECT',
  Website = 'WEBSITE'
}

export type ArrayStringFilterInput = {
  all?: Maybe<Array<Scalars['ID']>>;
  any?: Maybe<Array<Scalars['ID']>>;
};

export type Block = {
  __typename?: 'Block';
  id: Scalars['ID'];
  root: Scalars['Json'];
  sections: Array<Scalars['Json']>;
};

export type BlockTemplate = {
  __typename?: 'BlockTemplate';
  block: Block;
  blockId: Scalars['ID'];
  categories: Array<Scalars['String']>;
  id: Scalars['ID'];
  industries: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type BlockTemplateFilterInput = {
  name?: Maybe<StringFilterInput>;
};

export type BlockTemplateListResult = {
  __typename?: 'BlockTemplateListResult';
  entities: Array<BlockTemplate>;
  paging: Paging;
};

export enum BodyType {
  Block = 'BLOCK',
  Md = 'MD'
}

export type BooleanFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  id: Scalars['ID'];
  lang: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type BulkPatchEntryInput = {
  data: PatchEntryData;
  entryIds: Array<Scalars['ID']>;
};

export type BulkPatchTaxonInput = {
  data: PatchTaxonData;
  taxonIds: Array<Scalars['ID']>;
};

export type BulkUpdateTaxonInput = {
  data: UpdateTaxonData;
  taxonIds: Array<Scalars['ID']>;
};

export type BulkUpdateTaxonParentInput = {
  parentId?: Maybe<Scalars['ID']>;
  taxonIds: Array<Scalars['ID']>;
};

export type CreateBlockTemplateCommand = {
  categories: Array<Scalars['String']>;
  industries: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateEmailSenderInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  provider: EmailProvider;
};

export type CreateEmailTemplateInput = {
  code: Scalars['String'];
  emailSenderId: Scalars['ID'];
  html: Scalars['String'];
  md: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  text: Scalars['String'];
};

export type CreateEntryCommand = {
  bodyType?: Maybe<BodyType>;
  description?: Maybe<Scalars['String']>;
  entryTypeId: Scalars['ID'];
  featured?: Maybe<Scalars['Boolean']>;
  image?: Maybe<ImageValueInput>;
  images?: Maybe<Array<ImageValueInput>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  lang: Scalars['String'];
  md?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  pretitle?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sectionValues?: Maybe<Scalars['Json']>;
  seo?: Maybe<SeoInput>;
  slug: Scalars['String'];
  state?: Maybe<EntryState>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateEntryTypeCommand = {
  code: Scalars['String'];
  displayName: Scalars['String'];
  isProduct?: Maybe<Scalars['Boolean']>;
  routeId: Scalars['ID'];
};

export type CreateFieldCommand = {
  code: Scalars['String'];
  defaultValue?: Maybe<Scalars['Json']>;
  displayName: Scalars['String'];
  entryTypeId?: Maybe<Scalars['ID']>;
  taxonomyId?: Maybe<Scalars['ID']>;
  type: FieldValueType;
  /** If type is array, validation schema will be apply to the each children */
  validationSchema?: Maybe<Scalars['Json']>;
};

export type CreateFormInput = {
  code: Scalars['String'];
  notifyEmails: Array<Scalars['String']>;
  notifyTemplateId?: Maybe<Scalars['String']>;
  thankYouTemplateId?: Maybe<Scalars['String']>;
};

export type CreateLayoutCommand = {
  code: Scalars['String'];
};

export type CreatePortalCommand = {
  config?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  theme?: Maybe<Scalars['Json']>;
};

export type CreateProductCommand = {
  addOns: Array<ProductAddOnInput>;
  entryId: Scalars['ID'];
  fields: Array<ProductFieldInput>;
  optionTypes: Scalars['Json'];
  optionTypesId: Array<Scalars['ID']>;
  otNames: Scalars['Json'];
  ovNames: Scalars['Json'];
  variants: Array<VariantInput>;
};

export type CreateRedirectCommand = {
  from: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  to: Scalars['String'];
};

export type CreateRouteCommand = {
  lang: Scalars['String'];
  layoutId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  page?: Maybe<Scalars['String']>;
  pattern: Scalars['String'];
  seo?: Maybe<SeoInput>;
  sitemap?: Maybe<SitemapConfigInput>;
};

export type CreateSectionTemplateCommand = {
  categories: Array<Scalars['String']>;
  code: Scalars['String'];
  component: Scalars['String'];
  config: Scalars['Json'];
  industries: Array<Scalars['String']>;
  theme: Scalars['Json'];
  value: Scalars['Json'];
};

export type CreateSubscriberInput = {
  data?: Maybe<Scalars['Json']>;
  email?: Maybe<Scalars['String']>;
  formCode: Scalars['String'];
  labels?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  requestInfo?: Maybe<Scalars['Json']>;
  url?: Maybe<Scalars['String']>;
};

export type CreateTaxonCommand = {
  bodyType?: Maybe<BodyType>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image?: Maybe<ImageValueInput>;
  images?: Maybe<Array<ImageValueInput>>;
  lang: Scalars['String'];
  md?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Int']>;
  pretitle?: Maybe<Scalars['String']>;
  seo?: Maybe<SeoInput>;
  slug: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  taxonomyId: Scalars['ID'];
  title: Scalars['String'];
};

export type CreateTaxonomyCommand = {
  code: Scalars['String'];
  displayName: Scalars['String'];
  routeId: Scalars['ID'];
};

export enum Currency {
  Usd = 'USD',
  Vnd = 'VND'
}



export type DeleteEntryCommand = {
  entryId: Scalars['ID'];
};

export type DeleteFieldCommand = {
  fieldId: Scalars['ID'];
};

export type DeleteTaxonomyCommand = {
  taxonomyId: Scalars['ID'];
};

export enum EmailProvider {
  Aws = 'AWS',
  Smtp = 'SMTP'
}

export type EmailSender = {
  __typename?: 'EmailSender';
  email: Scalars['String'];
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  provider: EmailProvider;
};

export type EmailSenderFilterInput = {
  email?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
};

export type EmailSenderListResult = {
  __typename?: 'EmailSenderListResult';
  entities: Array<EmailSender>;
  paging: Paging;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  bodyType: TemplateBodyType;
  code: Scalars['String'];
  emailSender?: Maybe<EmailSender>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  md?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  text: Scalars['String'];
};

export type EmailTemplateFilterInput = {
  code?: Maybe<StringFilterInput>;
};

export type EmailTemplateListResult = {
  __typename?: 'EmailTemplateListResult';
  entities: Array<EmailTemplate>;
  paging: Paging;
};

export type Entry = {
  __typename?: 'Entry';
  blocks: Array<EntryBlock>;
  bodyType?: Maybe<BodyType>;
  breadcrumbs: Array<Breadcrumb>;
  description: Scalars['String'];
  entryEntriesFieldValues: Array<EntryEntriesFieldValue>;
  entryEntryFieldValues: Array<EntryEntryFieldValue>;
  entryTaxonFieldValues: Array<EntryTaxonFieldValue>;
  entryTaxonsFieldValues: Array<EntryTaxonsFieldValue>;
  entryTypeCode: Scalars['String'];
  featured: Scalars['Boolean'];
  fieldValues: Array<JsonFieldValue>;
  id: Scalars['ID'];
  image?: Maybe<ImageValue>;
  images: Array<ImageValue>;
  insertedAt: Scalars['DateTime'];
  keywords: Array<Scalars['String']>;
  md: Scalars['String'];
  permanentLink: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  pretitle: Scalars['String'];
  product?: Maybe<Product>;
  publishedAt: Scalars['DateTime'];
  rawFieldValues: Scalars['Json'];
  sectionValues: Scalars['Json'];
  seo: Seo;
  shortId: Scalars['ID'];
  slug: Scalars['String'];
  state: EntryState;
  subtitle: Scalars['String'];
  taxons: Array<Taxon>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type EntryBreadcrumbsArgs = {
  taxonomyCode?: Maybe<Scalars['String']>;
};


export type EntryEntryEntriesFieldValuesArgs = {
  fieldCode?: Maybe<Scalars['String']>;
};


export type EntryEntryEntryFieldValuesArgs = {
  fieldCode?: Maybe<Scalars['String']>;
};


export type EntryEntryTaxonFieldValuesArgs = {
  fieldCode?: Maybe<Scalars['String']>;
};


export type EntryEntryTaxonsFieldValuesArgs = {
  fieldCode?: Maybe<Scalars['String']>;
};


export type EntryTaxonsArgs = {
  paging?: Maybe<PagingInput>;
  taxonomyCode?: Maybe<Scalars['String']>;
};

export type EntryAggregatedByTaxonItem = {
  __typename?: 'EntryAggregatedByTaxonItem';
  count: Scalars['Int'];
  taxonId: Scalars['ID'];
  taxonShortId: Scalars['ID'];
  taxonomyCode: Scalars['String'];
};

export type EntryBlock = {
  __typename?: 'EntryBlock';
  block: Block;
  blockId: Scalars['ID'];
  key: Scalars['String'];
};

export type EntryEntriesFieldValue = {
  __typename?: 'EntryEntriesFieldValue';
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value: Array<EntryFieldValue>;
};

export type EntryEntryFieldValue = {
  __typename?: 'EntryEntryFieldValue';
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value?: Maybe<EntryFieldValue>;
};

export type EntryFieldValue = {
  __typename?: 'EntryFieldValue';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type EntryFilterInput = {
  entryTypeCode?: Maybe<StringFilterInput>;
  entryTypeId?: Maybe<IdFilterInput>;
  featured?: Maybe<BooleanFilterInput>;
  fulltext?: Maybe<FulltextFilterInput>;
  hasProduct?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<RelatedTo>;
  slug?: Maybe<StringFilterInput>;
  state?: Maybe<StringFilterInput>;
  taxonomies?: Maybe<EntryTaxonomiesFilterInput>;
  taxons?: Maybe<Array<TaxonFilterInput>>;
  title?: Maybe<StringFilterInput>;
};

export type EntryListResult = {
  __typename?: 'EntryListResult';
  entities: Array<Entry>;
  paging: Paging;
};

export enum EntryState {
  Archive = 'ARCHIVE',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Trash = 'TRASH'
}

export type EntryTaxonFieldValue = {
  __typename?: 'EntryTaxonFieldValue';
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value?: Maybe<TaxonFieldValue>;
};

export type EntryTaxonomiesFilterInput = {
  id: Scalars['ID'];
  isEmpty: Scalars['Boolean'];
};

export type EntryTaxonsFieldValue = {
  __typename?: 'EntryTaxonsFieldValue';
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value: Array<TaxonFieldValue>;
};

export type EntryType = {
  __typename?: 'EntryType';
  code: Scalars['String'];
  displayName: Scalars['String'];
  entriesCount: Scalars['Int'];
  fields: Array<EntryTypeField>;
  id: Scalars['ID'];
  isProduct: Scalars['Boolean'];
  route: Route;
  routeId: Scalars['ID'];
  routePattern: Scalars['String'];
  taxonomies: Array<EntryTypeTaxonomy>;
};

export type EntryTypeField = {
  __typename?: 'EntryTypeField';
  code: Scalars['String'];
  displayName: Scalars['String'];
  entryTypeId?: Maybe<Scalars['ID']>;
  id: Scalars['String'];
  taxonomyId?: Maybe<Scalars['ID']>;
  type: FieldValueType;
};

export type EntryTypeFilterInput = {
  code?: Maybe<StringFilterInput>;
  displayName?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
};

export type EntryTypeListResult = {
  __typename?: 'EntryTypeListResult';
  entities: Array<EntryType>;
  paging: Paging;
};

export type EntryTypeTaxonomy = {
  __typename?: 'EntryTypeTaxonomy';
  code: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['String'];
};

export type Field = {
  __typename?: 'Field';
  code: Scalars['String'];
  defaultValue?: Maybe<Scalars['Json']>;
  displayName: Scalars['String'];
  entryTypeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  taxonomyId?: Maybe<Scalars['ID']>;
  type: FieldValueType;
  validationSchema?: Maybe<Scalars['Json']>;
};

export type FieldFilterInput = {
  code?: Maybe<StringFilterInput>;
  displayName?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
};

export type FieldListResult = {
  __typename?: 'FieldListResult';
  entities: Array<Field>;
  paging: Paging;
};

export type FieldValue = {
  __typename?: 'FieldValue';
  code: Scalars['String'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export enum FieldValueType {
  Boolean = 'BOOLEAN',
  ColorPalette = 'COLOR_PALETTE',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Entries = 'ENTRIES',
  Entry = 'ENTRY',
  Enum = 'ENUM',
  EnumArray = 'ENUM_ARRAY',
  Integer = 'INTEGER',
  Json = 'JSON',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  Taxon = 'TAXON',
  Taxons = 'TAXONS',
  Time = 'TIME'
}

export type Form = {
  __typename?: 'Form';
  code: Scalars['String'];
  id: Scalars['ID'];
  notifyEmails?: Maybe<Array<Scalars['String']>>;
  notifyTemplate?: Maybe<EmailTemplate>;
  thankYouTemplate?: Maybe<EmailTemplate>;
};

export type FormFilterInput = {
  code?: Maybe<StringFilterInput>;
};

export type FormListResult = {
  __typename?: 'FormListResult';
  entities: Array<Form>;
  paging: Paging;
};

export type FulltextFilterInput = {
  websearch?: Maybe<Scalars['String']>;
};

export type IdFilterInput = {
  eq?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  neq?: Maybe<Scalars['ID']>;
  nin?: Maybe<Array<Scalars['ID']>>;
};

export type Image = {
  __typename?: 'Image';
  caption?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remoteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Decimal']>;
};

export type ImageFilterInput = {
  id?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
};

export type ImageListResult = {
  __typename?: 'ImageListResult';
  entities: Array<Image>;
  paging: Paging;
};

export type ImageValue = {
  __typename?: 'ImageValue';
  alt?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  size?: Maybe<Scalars['Decimal']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Decimal']>;
};

export type ImageValueInput = {
  alt?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  size?: Maybe<Scalars['Decimal']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Decimal']>;
};

export type IntegerFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};


export type JsonFieldValue = {
  __typename?: 'JsonFieldValue';
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value?: Maybe<Scalars['Json']>;
};

export type Keyword = {
  __typename?: 'Keyword';
  competition?: Maybe<Scalars['Int']>;
  entriesCount?: Maybe<Scalars['Int']>;
  keyword: Scalars['ID'];
  lang: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  monthlySearches?: Maybe<Scalars['Int']>;
};

export type KeywordFilterInput = {
  entriesCount?: Maybe<IntegerFilterInput>;
  keyword?: Maybe<StringFilterInput>;
  monthlySearches?: Maybe<IntegerFilterInput>;
};

export type KeywordListResult = {
  __typename?: 'KeywordListResult';
  entities: Array<Keyword>;
  paging: Paging;
};

export type Layout = {
  __typename?: 'Layout';
  block?: Maybe<Block>;
  blockId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  fieldValues: Array<FieldValue>;
  id: Scalars['ID'];
};

export type LayoutFilterInput = {
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<StringFilterInput>;
};

export type LayoutListResult = {
  __typename?: 'LayoutListResult';
  entities: Array<Layout>;
  paging: Paging;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  currency: Currency;
};

export type MoneyInput = {
  amount: Scalars['Int'];
  currency: Currency;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  addEntriesTaxon: Array<Scalars['ID']>;
  addEntryBlock?: Maybe<Entry>;
  addEntrySectionValue?: Maybe<Website>;
  addEntryTaxon?: Maybe<Entry>;
  addEntryTypeField: EntryType;
  addEntryTypeTaxonomy: EntryType;
  addLayoutBlock?: Maybe<Layout>;
  addLayoutFieldValue?: Maybe<Layout>;
  addOrderItem?: Maybe<Order>;
  addOrderItems?: Maybe<Order>;
  addRouteBlock?: Maybe<Route>;
  addRouteFieldValue?: Maybe<Route>;
  addRouteSectionConfig?: Maybe<Website>;
  addRouteSectionTheme?: Maybe<Website>;
  addRouteSectionValue?: Maybe<Website>;
  addWebsiteFieldValue?: Maybe<Website>;
  addWebsiteSectionConfig?: Maybe<Website>;
  addWebsiteSectionTheme?: Maybe<Website>;
  addWebsiteSectionValue?: Maybe<Website>;
  bulkCreateTaxon: Scalars['Int'];
  bulkPatchEntry?: Maybe<Scalars['Int']>;
  bulkPatchTaxon?: Maybe<Scalars['Int']>;
  bulkUpdateTaxon?: Maybe<Scalars['Int']>;
  bulkUpdateTaxonParent?: Maybe<Scalars['Int']>;
  createBlockTemplate?: Maybe<BlockTemplate>;
  createEmailSender?: Maybe<EmailSender>;
  createEmailTemplate?: Maybe<EmailTemplate>;
  createEntry?: Maybe<Entry>;
  createEntryType?: Maybe<EntryType>;
  createField?: Maybe<Field>;
  createForm?: Maybe<Form>;
  createLayout?: Maybe<Layout>;
  createOrder?: Maybe<Order>;
  createPortal?: Maybe<Portal>;
  createProduct?: Maybe<Product>;
  createRedirect?: Maybe<Redirect>;
  createRoute?: Maybe<Route>;
  createSectionTemplate?: Maybe<SectionTemplate>;
  createSubscriber?: Maybe<Subscriber>;
  createTaxon?: Maybe<Taxon>;
  createTaxonomy: Taxonomy;
  deleteEntry?: Maybe<Entry>;
  deleteField: Field;
  deleteImage: Image;
  deleteTaxonomy: Taxonomy;
  patchEntry?: Maybe<Entry>;
  patchTaxon?: Maybe<Taxon>;
  removeEntriesTaxon: Array<Scalars['ID']>;
  removeEntryBlock?: Maybe<Entry>;
  removeEntrySectionValue?: Maybe<Website>;
  removeEntryTaxon?: Maybe<Entry>;
  removeEntryTypeField: EntryType;
  removeEntryTypeTaxonomy: EntryType;
  removeLayoutFieldValue?: Maybe<Layout>;
  removeOrderItem?: Maybe<Order>;
  removeRouteFieldValue?: Maybe<Route>;
  removeRouteSectionConfig?: Maybe<Website>;
  removeRouteSectionTheme?: Maybe<Website>;
  removeRouteSectionValue?: Maybe<Website>;
  removeWebsiteFieldValue?: Maybe<Website>;
  removeWebsiteSectionConfig?: Maybe<Website>;
  removeWebsiteSectionTheme?: Maybe<Website>;
  removeWebsiteSectionValue?: Maybe<Website>;
  renameEntryBlock?: Maybe<Entry>;
  sendEmailVerification?: Maybe<EmailSender>;
  submitOrder?: Maybe<Order>;
  testEmailSender?: Maybe<Scalars['Boolean']>;
  updateBlock: Block;
  updateBlockTemplate: BlockTemplate;
  updateEmailSender: EmailSender;
  updateEmailTemplate: EmailTemplate;
  updateEntry?: Maybe<Entry>;
  updateEntryEntriesFieldValue?: Maybe<Entry>;
  updateEntryEntryFieldValue?: Maybe<Entry>;
  updateEntryFieldValue?: Maybe<Entry>;
  updateEntrySectionValue?: Maybe<Website>;
  updateEntryTaxonFieldValue?: Maybe<Entry>;
  updateEntryTaxonsFieldValue?: Maybe<Entry>;
  updateEntryType: EntryType;
  updateField: Field;
  updateForm: Form;
  updateLayout?: Maybe<Layout>;
  updateLayoutFieldValue?: Maybe<Layout>;
  updateOrder?: Maybe<Order>;
  updateOrderItem?: Maybe<Order>;
  updatePortal: Portal;
  updateProduct: Product;
  updateRedirect?: Maybe<Redirect>;
  updateRoute?: Maybe<Route>;
  updateRouteFieldValue?: Maybe<Route>;
  updateRouteSectionConfig?: Maybe<Website>;
  updateRouteSectionTheme?: Maybe<Website>;
  updateRouteSectionValue?: Maybe<Website>;
  updateSectionTemplate?: Maybe<SectionTemplate>;
  updateTaxon?: Maybe<Taxon>;
  updateTaxonParent?: Maybe<Taxon>;
  updateTaxonomy: Taxonomy;
  updateWebsite?: Maybe<Website>;
  updateWebsiteFieldValue?: Maybe<Website>;
  updateWebsiteSectionConfig?: Maybe<Website>;
  updateWebsiteSectionTheme?: Maybe<Website>;
  updateWebsiteSectionValue?: Maybe<Website>;
  uploadImage: Array<Image>;
  verifyEmailSender?: Maybe<EmailSender>;
};


export type RootMutationTypeAddEntriesTaxonArgs = {
  data: AddEntriesTaxonCommand;
};


export type RootMutationTypeAddEntryBlockArgs = {
  data: AddEntryBlockCommand;
};


export type RootMutationTypeAddEntrySectionValueArgs = {
  data: AddEntrySectionValueCommand;
};


export type RootMutationTypeAddEntryTaxonArgs = {
  data: AddEntryTaxonCommand;
};


export type RootMutationTypeAddEntryTypeFieldArgs = {
  data: AddEntryTypeFieldCommand;
};


export type RootMutationTypeAddEntryTypeTaxonomyArgs = {
  data: AddEntryTypeTaxonomyCommand;
};


export type RootMutationTypeAddLayoutBlockArgs = {
  data: AddLayoutBlockCommand;
};


export type RootMutationTypeAddLayoutFieldValueArgs = {
  data: AddLayoutFieldValueCommand;
};


export type RootMutationTypeAddOrderItemArgs = {
  data: AddOrderItemCommand;
};


export type RootMutationTypeAddOrderItemsArgs = {
  data: AddOrderItemsInput;
};


export type RootMutationTypeAddRouteBlockArgs = {
  data: AddRouteBlockCommand;
};


export type RootMutationTypeAddRouteFieldValueArgs = {
  data: AddRouteFieldValueCommand;
};


export type RootMutationTypeAddRouteSectionConfigArgs = {
  data: AddRouteSectionConfigCommand;
};


export type RootMutationTypeAddRouteSectionThemeArgs = {
  data: AddRouteSectionThemeCommand;
};


export type RootMutationTypeAddRouteSectionValueArgs = {
  data: AddRouteSectionValueCommand;
};


export type RootMutationTypeAddWebsiteFieldValueArgs = {
  data: AddWebsiteFieldValueCommand;
};


export type RootMutationTypeAddWebsiteSectionConfigArgs = {
  data: AddWebsiteSectionConfigCommand;
};


export type RootMutationTypeAddWebsiteSectionThemeArgs = {
  data: AddWebsiteSectionThemeCommand;
};


export type RootMutationTypeAddWebsiteSectionValueArgs = {
  data: AddWebsiteSectionValueCommand;
};


export type RootMutationTypeBulkCreateTaxonArgs = {
  data: Array<CreateTaxonCommand>;
};


export type RootMutationTypeBulkPatchEntryArgs = {
  data: BulkPatchEntryInput;
};


export type RootMutationTypeBulkPatchTaxonArgs = {
  data: BulkPatchTaxonInput;
};


export type RootMutationTypeBulkUpdateTaxonArgs = {
  data: BulkUpdateTaxonInput;
};


export type RootMutationTypeBulkUpdateTaxonParentArgs = {
  data: BulkUpdateTaxonParentInput;
};


export type RootMutationTypeCreateBlockTemplateArgs = {
  data: CreateBlockTemplateCommand;
};


export type RootMutationTypeCreateEmailSenderArgs = {
  data: CreateEmailSenderInput;
};


export type RootMutationTypeCreateEmailTemplateArgs = {
  data: CreateEmailTemplateInput;
};


export type RootMutationTypeCreateEntryArgs = {
  data: CreateEntryCommand;
};


export type RootMutationTypeCreateEntryTypeArgs = {
  data: CreateEntryTypeCommand;
};


export type RootMutationTypeCreateFieldArgs = {
  data: CreateFieldCommand;
};


export type RootMutationTypeCreateFormArgs = {
  data: CreateFormInput;
};


export type RootMutationTypeCreateLayoutArgs = {
  data: CreateLayoutCommand;
};


export type RootMutationTypeCreatePortalArgs = {
  data: CreatePortalCommand;
};


export type RootMutationTypeCreateProductArgs = {
  data: CreateProductCommand;
};


export type RootMutationTypeCreateRedirectArgs = {
  data: CreateRedirectCommand;
};


export type RootMutationTypeCreateRouteArgs = {
  data: CreateRouteCommand;
};


export type RootMutationTypeCreateSectionTemplateArgs = {
  data: CreateSectionTemplateCommand;
};


export type RootMutationTypeCreateSubscriberArgs = {
  data: CreateSubscriberInput;
};


export type RootMutationTypeCreateTaxonArgs = {
  data: CreateTaxonCommand;
};


export type RootMutationTypeCreateTaxonomyArgs = {
  data: CreateTaxonomyCommand;
};


export type RootMutationTypeDeleteEntryArgs = {
  data: DeleteEntryCommand;
};


export type RootMutationTypeDeleteFieldArgs = {
  data: DeleteFieldCommand;
};


export type RootMutationTypeDeleteImageArgs = {
  id: Scalars['String'];
};


export type RootMutationTypeDeleteTaxonomyArgs = {
  data: DeleteTaxonomyCommand;
};


export type RootMutationTypePatchEntryArgs = {
  data: PatchEntryCommand;
};


export type RootMutationTypePatchTaxonArgs = {
  data: PatchTaxonCommand;
};


export type RootMutationTypeRemoveEntriesTaxonArgs = {
  data: RemoveEntriesTaxonCommand;
};


export type RootMutationTypeRemoveEntryBlockArgs = {
  data: RemoveEntryBlockCommand;
};


export type RootMutationTypeRemoveEntrySectionValueArgs = {
  data: RemoveEntrySectionValueCommand;
};


export type RootMutationTypeRemoveEntryTaxonArgs = {
  data: RemoveEntryTaxonCommand;
};


export type RootMutationTypeRemoveEntryTypeFieldArgs = {
  data: RemoveEntryTypeFieldCommand;
};


export type RootMutationTypeRemoveEntryTypeTaxonomyArgs = {
  data: RemoveEntryTypeTaxonomyCommand;
};


export type RootMutationTypeRemoveLayoutFieldValueArgs = {
  data: RemoveLayoutFieldValueCommand;
};


export type RootMutationTypeRemoveOrderItemArgs = {
  data: RemoveOrderItemCommand;
};


export type RootMutationTypeRemoveRouteFieldValueArgs = {
  data: RemoveRouteFieldValueCommand;
};


export type RootMutationTypeRemoveRouteSectionConfigArgs = {
  data: RemoveRouteSectionConfigCommand;
};


export type RootMutationTypeRemoveRouteSectionThemeArgs = {
  data: RemoveRouteSectionThemeCommand;
};


export type RootMutationTypeRemoveRouteSectionValueArgs = {
  data: RemoveRouteSectionValueCommand;
};


export type RootMutationTypeRemoveWebsiteFieldValueArgs = {
  data: RemoveWebsiteFieldValueCommand;
};


export type RootMutationTypeRemoveWebsiteSectionConfigArgs = {
  data: RemoveWebsiteSectionConfigCommand;
};


export type RootMutationTypeRemoveWebsiteSectionThemeArgs = {
  data: RemoveWebsiteSectionThemeCommand;
};


export type RootMutationTypeRemoveWebsiteSectionValueArgs = {
  data: RemoveWebsiteSectionValueCommand;
};


export type RootMutationTypeRenameEntryBlockArgs = {
  data: RenameEntryBlockCommand;
};


export type RootMutationTypeSendEmailVerificationArgs = {
  data: SendEmailVerificationInput;
};


export type RootMutationTypeSubmitOrderArgs = {
  data: SubmitOrderCommand;
};


export type RootMutationTypeTestEmailSenderArgs = {
  data: TestEmailSenderInput;
};


export type RootMutationTypeUpdateBlockArgs = {
  data: UpdateBlockCommand;
};


export type RootMutationTypeUpdateBlockTemplateArgs = {
  data: UpdateBlockTemplateCommand;
};


export type RootMutationTypeUpdateEmailSenderArgs = {
  data: UpdateEmailSenderInput;
};


export type RootMutationTypeUpdateEmailTemplateArgs = {
  data: UpdateEmailTemplateInput;
};


export type RootMutationTypeUpdateEntryArgs = {
  data: UpdateEntryCommand;
};


export type RootMutationTypeUpdateEntryEntriesFieldValueArgs = {
  data: UpdateEntryEntriesFieldValueCommand;
};


export type RootMutationTypeUpdateEntryEntryFieldValueArgs = {
  data: UpdateEntryEntryFieldValueCommand;
};


export type RootMutationTypeUpdateEntryFieldValueArgs = {
  data: UpdateEntryFieldValueCommand;
};


export type RootMutationTypeUpdateEntrySectionValueArgs = {
  data: UpdateEntrySectionValueCommand;
};


export type RootMutationTypeUpdateEntryTaxonFieldValueArgs = {
  data: UpdateEntryTaxonFieldValueCommand;
};


export type RootMutationTypeUpdateEntryTaxonsFieldValueArgs = {
  data: UpdateEntryTaxonsFieldValueCommand;
};


export type RootMutationTypeUpdateEntryTypeArgs = {
  data: UpdateEntryTypeCommand;
};


export type RootMutationTypeUpdateFieldArgs = {
  data: UpdateFieldCommand;
};


export type RootMutationTypeUpdateFormArgs = {
  data: UpdateFormInput;
};


export type RootMutationTypeUpdateLayoutArgs = {
  data: UpdateLayoutCommand;
};


export type RootMutationTypeUpdateLayoutFieldValueArgs = {
  data: UpdateLayoutFieldValueCommand;
};


export type RootMutationTypeUpdateOrderArgs = {
  data: UpdateOrderCommand;
};


export type RootMutationTypeUpdateOrderItemArgs = {
  data: UpdateOrderItemCommand;
};


export type RootMutationTypeUpdatePortalArgs = {
  data: UpdatePortalCommand;
};


export type RootMutationTypeUpdateProductArgs = {
  data: UpdateProductCommand;
};


export type RootMutationTypeUpdateRedirectArgs = {
  data: UpdateRedirectCommand;
};


export type RootMutationTypeUpdateRouteArgs = {
  data: UpdateRouteCommand;
};


export type RootMutationTypeUpdateRouteFieldValueArgs = {
  data: UpdateRouteFieldValueCommand;
};


export type RootMutationTypeUpdateRouteSectionConfigArgs = {
  data: UpdateRouteSectionConfigCommand;
};


export type RootMutationTypeUpdateRouteSectionThemeArgs = {
  data: UpdateRouteSectionThemeCommand;
};


export type RootMutationTypeUpdateRouteSectionValueArgs = {
  data: UpdateRouteSectionValueCommand;
};


export type RootMutationTypeUpdateSectionTemplateArgs = {
  data: UpdateSectionTemplateCommand;
};


export type RootMutationTypeUpdateTaxonArgs = {
  data: UpdateTaxonCommand;
};


export type RootMutationTypeUpdateTaxonParentArgs = {
  data: UpdateTaxonParentCommand;
};


export type RootMutationTypeUpdateTaxonomyArgs = {
  data: UpdateTaxonomyCommand;
};


export type RootMutationTypeUpdateWebsiteArgs = {
  data: UpdateWebsiteCommand;
};


export type RootMutationTypeUpdateWebsiteFieldValueArgs = {
  data: UpdateWebsiteFieldValueCommand;
};


export type RootMutationTypeUpdateWebsiteSectionConfigArgs = {
  data: UpdateWebsiteSectionConfigCommand;
};


export type RootMutationTypeUpdateWebsiteSectionThemeArgs = {
  data: UpdateWebsiteSectionThemeCommand;
};


export type RootMutationTypeUpdateWebsiteSectionValueArgs = {
  data: UpdateWebsiteSectionValueCommand;
};


export type RootMutationTypeUploadImageArgs = {
  files: Array<Scalars['Upload']>;
  strategy?: Maybe<UploadImageStrategy>;
};


export type RootMutationTypeVerifyEmailSenderArgs = {
  data: VerifyEmailSenderInput;
};

export type Order = {
  __typename?: 'Order';
  billingAddress?: Maybe<Address>;
  id: Scalars['ID'];
  items: Array<OrderItem>;
  note?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
  state?: Maybe<OrderState>;
  total: Money;
};

export type OrderByInput = {
  direction: OrderDirection;
  field: Scalars['String'];
  /** Use for order field in entry_integer_value, entry_decimal_value & friends */
  fieldType?: Maybe<OrderFieldType>;
};

export enum OrderDirection {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

export enum OrderFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Time = 'TIME'
}

export type OrderFilterInput = {
  state?: Maybe<StringFilterInput>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  amount: Money;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  price: Money;
  quantity: Scalars['Int'];
  variant: Variant;
};

export type OrderItemInput = {
  quantity?: Maybe<Scalars['Int']>;
  variantId: Scalars['ID'];
};

export type OrderListResult = {
  __typename?: 'OrderListResult';
  entities: Array<Order>;
  paging: Paging;
};

export enum OrderState {
  Cancelled = 'CANCELLED',
  Cart = 'CART',
  Completed = 'COMPLETED',
  Submitted = 'SUBMITTED'
}

export type Paging = {
  __typename?: 'Paging';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntities: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PagingInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PatchEntryCommand = {
  data: PatchEntryData;
  entryId: Scalars['ID'];
};

export type PatchEntryData = {
  bodyType?: Maybe<BodyType>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageValueInput>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  md?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  pretitle?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seo?: Maybe<SeoInput>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<EntryState>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PatchTaxonCommand = {
  data: PatchTaxonData;
  taxonId: Scalars['ID'];
};

export type PatchTaxonData = {
  bodyType?: Maybe<BodyType>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageValueInput>>;
  md?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  pretitle?: Maybe<Scalars['String']>;
  seo?: Maybe<SeoInput>;
  slug?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PathFilterInput = {
  eq?: Maybe<Array<Scalars['String']>>;
  in?: Maybe<Array<Scalars['String']>>;
  match?: Maybe<Scalars['String']>;
};

export type Portal = {
  __typename?: 'Portal';
  block: Block;
  blockId: Scalars['ID'];
  config?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Scalars['Json']>;
};

export type PortalFilterInput = {
  name?: Maybe<StringFilterInput>;
};

export type PortalListResult = {
  __typename?: 'PortalListResult';
  entities: Array<Portal>;
  paging: Paging;
};

export enum PriceAdjustment {
  Percentage = 'PERCENTAGE',
  Value = 'VALUE'
}

export type PriceAdjustmentConfig = {
  __typename?: 'PriceAdjustmentConfig';
  type: PriceAdjustment;
  value: Money;
};

export type PriceAdjustmentConfigInput = {
  type: PriceAdjustment;
  value: MoneyInput;
};

export type Product = {
  __typename?: 'Product';
  addOns: Array<ProductAddOn>;
  entry: Entry;
  entryId: Scalars['ID'];
  fields: Array<ProductField>;
  id: Scalars['ID'];
  maxListPrice?: Maybe<Money>;
  maxPercentageSaved?: Maybe<Scalars['Decimal']>;
  maxPrice?: Maybe<Money>;
  minListPrice?: Maybe<Money>;
  minPrice?: Maybe<Money>;
  optionTypes: Scalars['Json'];
  optionTypesId: Array<Scalars['ID']>;
  otNames: Scalars['Json'];
  ovNames: Scalars['Json'];
  variants: Array<Variant>;
};

export type ProductAddOn = {
  __typename?: 'ProductAddOn';
  adjustment?: Maybe<PriceAdjustmentConfig>;
  code: Scalars['String'];
  items: Array<ProductAddOnItem>;
  type: ProductAddOnType;
};

export type ProductAddOnInput = {
  adjustment?: Maybe<PriceAdjustmentConfigInput>;
  code: Scalars['String'];
  items: Array<ProductAddOnItemInput>;
  type: ProductAddOnType;
};

export type ProductAddOnItem = {
  __typename?: 'ProductAddOnItem';
  adjustment: PriceAdjustmentConfig;
  code: Scalars['String'];
};

export type ProductAddOnItemInput = {
  adjustment: PriceAdjustmentConfigInput;
  code: Scalars['String'];
};

export enum ProductAddOnType {
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  MultiSelect = 'MULTI_SELECT',
  Select = 'SELECT'
}

export type ProductField = {
  __typename?: 'ProductField';
  code: Scalars['String'];
  config?: Maybe<Scalars['Json']>;
  type: ProductFieldType;
};

export type ProductFieldInput = {
  code: Scalars['String'];
  config?: Maybe<Scalars['Json']>;
  type: ProductFieldType;
};

export enum ProductFieldType {
  Boolean = 'BOOLEAN',
  Image = 'IMAGE',
  ImageList = 'IMAGE_LIST',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type RootQueryType = {
  __typename?: 'RootQueryType';
  block?: Maybe<Block>;
  blockTemplate?: Maybe<BlockTemplate>;
  blockTemplateBy?: Maybe<BlockTemplate>;
  blockTemplates: BlockTemplateListResult;
  emailSender?: Maybe<EmailSender>;
  emailSenderBy?: Maybe<EmailSender>;
  emailSenders: EmailSenderListResult;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplateBy?: Maybe<EmailTemplate>;
  emailTemplates: EmailTemplateListResult;
  entries: EntryListResult;
  entry?: Maybe<Entry>;
  entryAggregatedByTaxon: Array<EntryAggregatedByTaxonItem>;
  entryBy?: Maybe<Entry>;
  entryType?: Maybe<EntryType>;
  entryTypeBy?: Maybe<EntryType>;
  entryTypes: EntryTypeListResult;
  field?: Maybe<Field>;
  fieldBy?: Maybe<Field>;
  fields: FieldListResult;
  form?: Maybe<Form>;
  formBy?: Maybe<Form>;
  forms: FormListResult;
  images: ImageListResult;
  keywords: KeywordListResult;
  layout?: Maybe<Layout>;
  layoutBy?: Maybe<Layout>;
  layouts: LayoutListResult;
  order?: Maybe<Order>;
  orders: OrderListResult;
  portal?: Maybe<Portal>;
  portalBy?: Maybe<Portal>;
  portals: PortalListResult;
  redirect?: Maybe<Redirect>;
  redirectBy?: Maybe<Redirect>;
  redirects: RedirectListResult;
  route?: Maybe<Route>;
  routeBy?: Maybe<Route>;
  routes: RouteListResult;
  sectionTemplate?: Maybe<SectionTemplate>;
  sectionTemplateBy?: Maybe<SectionTemplate>;
  sectionTemplates: SectionTemplateListResult;
  subscribers: SubscriberListResult;
  taxon?: Maybe<Taxon>;
  taxonBy?: Maybe<Taxon>;
  taxonomies: TaxonomyListResult;
  taxonomy?: Maybe<Taxonomy>;
  taxonomyBy?: Maybe<Taxonomy>;
  taxons: TaxonListResult;
  website?: Maybe<Website>;
  websiteData: WebsiteData;
  websiteOrRedirectData?: Maybe<WebsiteOrRedirectData>;
};


export type RootQueryTypeBlockArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeBlockTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeBlockTemplateByArgs = {
  filter?: Maybe<BlockTemplateFilterInput>;
};


export type RootQueryTypeBlockTemplatesArgs = {
  filter?: Maybe<BlockTemplateFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeEmailSenderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeEmailSenderByArgs = {
  filter?: Maybe<EmailSenderFilterInput>;
};


export type RootQueryTypeEmailSendersArgs = {
  filter?: Maybe<EmailSenderFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeEmailTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeEmailTemplateByArgs = {
  filter?: Maybe<EmailTemplateFilterInput>;
};


export type RootQueryTypeEmailTemplatesArgs = {
  filter?: Maybe<EmailTemplateFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeEntriesArgs = {
  filter?: Maybe<EntryFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeEntryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeEntryAggregatedByTaxonArgs = {
  entriesFilter?: Maybe<EntryFilterInput>;
  taxonsFilter?: Maybe<TaxonFilterInput>;
};


export type RootQueryTypeEntryByArgs = {
  filter?: Maybe<EntryFilterInput>;
};


export type RootQueryTypeEntryTypeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeEntryTypeByArgs = {
  filter?: Maybe<EntryTypeFilterInput>;
};


export type RootQueryTypeEntryTypesArgs = {
  filter?: Maybe<EntryTypeFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeFieldArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeFieldByArgs = {
  filter?: Maybe<FieldFilterInput>;
};


export type RootQueryTypeFieldsArgs = {
  filter?: Maybe<FieldFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeFormArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeFormByArgs = {
  filter?: Maybe<FormFilterInput>;
};


export type RootQueryTypeFormsArgs = {
  filter?: Maybe<FormFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeImagesArgs = {
  filter?: Maybe<ImageFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeKeywordsArgs = {
  filter?: Maybe<KeywordFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeLayoutArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeLayoutByArgs = {
  filter?: Maybe<LayoutFilterInput>;
};


export type RootQueryTypeLayoutsArgs = {
  filter?: Maybe<LayoutFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeOrderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeOrdersArgs = {
  filter?: Maybe<OrderFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypePortalArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypePortalByArgs = {
  filter?: Maybe<PortalFilterInput>;
};


export type RootQueryTypePortalsArgs = {
  filter?: Maybe<PortalFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeRedirectArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeRedirectByArgs = {
  filter?: Maybe<RedirectFilterInput>;
};


export type RootQueryTypeRedirectsArgs = {
  filter?: Maybe<RedirectFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeRouteArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeRouteByArgs = {
  filter?: Maybe<RouteFilterInput>;
};


export type RootQueryTypeRoutesArgs = {
  filter?: Maybe<RouteFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeSectionTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeSectionTemplateByArgs = {
  filter?: Maybe<SectionTemplateFilterInput>;
};


export type RootQueryTypeSectionTemplatesArgs = {
  filter?: Maybe<SectionTemplateFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeSubscribersArgs = {
  filter?: Maybe<SubscriberFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeTaxonArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeTaxonByArgs = {
  filter?: Maybe<TaxonFilterInput>;
};


export type RootQueryTypeTaxonomiesArgs = {
  filter?: Maybe<TaxonomyFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeTaxonomyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootQueryTypeTaxonomyByArgs = {
  filter?: Maybe<TaxonomyFilterInput>;
};


export type RootQueryTypeTaxonsArgs = {
  filter?: Maybe<TaxonFilterInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
  paging?: Maybe<PagingInput>;
};


export type RootQueryTypeWebsiteOrRedirectDataArgs = {
  path: Scalars['String'];
};

export type Redirect = {
  __typename?: 'Redirect';
  from?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
};

export type RedirectData = {
  __typename?: 'RedirectData';
  redirect: Redirect;
  type: AppDataType;
};

export type RedirectFilterInput = {
  from?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  to?: Maybe<StringFilterInput>;
};

export type RedirectListResult = {
  __typename?: 'RedirectListResult';
  entities: Array<Redirect>;
  paging: Paging;
};

export type RelatedTo = {
  entryTypeCode: Scalars['String'];
  lang: Scalars['String'];
  slug: Scalars['String'];
  taxonomyCode: Scalars['String'];
};

export type RemoveEntriesTaxonCommand = {
  entriesId: Array<Scalars['ID']>;
  taxonId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type RemoveEntryBlockCommand = {
  entryId: Scalars['ID'];
  name: Scalars['String'];
};

export type RemoveEntrySectionValueCommand = {
  code: Scalars['String'];
  entryId: Scalars['ID'];
  type: SectionValueType;
};

export type RemoveEntryTaxonCommand = {
  entryId: Scalars['ID'];
  taxonId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type RemoveEntryTypeFieldCommand = {
  entryTypeId: Scalars['ID'];
  fieldId: Scalars['ID'];
};

export type RemoveEntryTypeTaxonomyCommand = {
  entryTypeId: Scalars['ID'];
  taxonomyId: Scalars['ID'];
};

export type RemoveLayoutFieldValueCommand = {
  code: Scalars['String'];
  layoutId: Scalars['ID'];
};

export type RemoveOrderItemCommand = {
  key?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  variantId: Scalars['ID'];
};

export type RemoveRouteFieldValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
};

export type RemoveRouteSectionConfigCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionConfigType;
};

export type RemoveRouteSectionThemeCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionThemeType;
};

export type RemoveRouteSectionValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionValueType;
};

export type RemoveWebsiteFieldValueCommand = {
  code: Scalars['String'];
};

export type RemoveWebsiteSectionConfigCommand = {
  code: Scalars['String'];
  type: SectionConfigType;
};

export type RemoveWebsiteSectionThemeCommand = {
  code: Scalars['String'];
  type: SectionThemeType;
};

export type RemoveWebsiteSectionValueCommand = {
  code: Scalars['String'];
  type: SectionValueType;
};

export type RenameEntryBlockCommand = {
  blockId: Scalars['ID'];
  entryId: Scalars['ID'];
  newName: Scalars['String'];
  oldName: Scalars['String'];
};

export type Route = {
  __typename?: 'Route';
  block?: Maybe<Block>;
  blockId?: Maybe<Scalars['ID']>;
  fieldValues: Array<FieldValue>;
  id: Scalars['ID'];
  lang: Scalars['String'];
  layout?: Maybe<Layout>;
  layoutId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  page: Scalars['String'];
  pattern: Scalars['String'];
  sectionConfigs: Scalars['Json'];
  sectionThemes: Scalars['Json'];
  sectionValues: Scalars['Json'];
  seo: Seo;
  sitemap?: Maybe<SitemapConfig>;
};

export type RouteFilterInput = {
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<StringFilterInput>;
};

export type RouteListResult = {
  __typename?: 'RouteListResult';
  entities: Array<Route>;
  paging: Paging;
};

export enum SectionConfigType {
  FaqAccordion = 'FAQ_ACCORDION',
  FaqList = 'FAQ_LIST',
  Form = 'FORM',
  Gallery = 'GALLERY',
  ImageItem = 'IMAGE_ITEM',
  ImageItemList = 'IMAGE_ITEM_LIST',
  InfoItem = 'INFO_ITEM',
  InfoItemList = 'INFO_ITEM_LIST',
  LabelValueList = 'LABEL_VALUE_LIST',
  Nav = 'NAV',
  PersonItemList = 'PERSON_ITEM_LIST',
  VideoPlayer = 'VIDEO_PLAYER'
}

export type SectionTemplate = {
  __typename?: 'SectionTemplate';
  categories: Array<Scalars['String']>;
  code: Scalars['String'];
  component: Scalars['String'];
  config?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  industries: Array<Scalars['String']>;
  theme?: Maybe<Scalars['Json']>;
  value?: Maybe<Scalars['Json']>;
};

export type SectionTemplateFilterInput = {
  categories?: Maybe<ArrayStringFilterInput>;
  code?: Maybe<StringFilterInput>;
  industries?: Maybe<ArrayStringFilterInput>;
};

export type SectionTemplateListResult = {
  __typename?: 'SectionTemplateListResult';
  entities: Array<SectionTemplate>;
  paging: Paging;
};

export enum SectionThemeType {
  FaqAccordion = 'FAQ_ACCORDION',
  FaqList = 'FAQ_LIST',
  Form = 'FORM',
  Gallery = 'GALLERY',
  ImageItem = 'IMAGE_ITEM',
  ImageItemList = 'IMAGE_ITEM_LIST',
  InfoItem = 'INFO_ITEM',
  InfoItemList = 'INFO_ITEM_LIST',
  LabelValueList = 'LABEL_VALUE_LIST',
  Nav = 'NAV',
  NavItem = 'NAV_ITEM',
  PersonItemList = 'PERSON_ITEM_LIST',
  VideoPlayer = 'VIDEO_PLAYER'
}

export enum SectionValueType {
  CountDown = 'COUNT_DOWN',
  CountUp = 'COUNT_UP',
  DataTable = 'DATA_TABLE',
  FaqList = 'FAQ_LIST',
  Form = 'FORM',
  GoogleMap = 'GOOGLE_MAP',
  Header = 'HEADER',
  Image = 'IMAGE',
  ImageItem = 'IMAGE_ITEM',
  ImageItemList = 'IMAGE_ITEM_LIST',
  InfoItem = 'INFO_ITEM',
  InfoItemList = 'INFO_ITEM_LIST',
  LabelValueList = 'LABEL_VALUE_LIST',
  Markdown = 'MARKDOWN',
  Nav = 'NAV',
  NavItem = 'NAV_ITEM',
  PersonItem = 'PERSON_ITEM',
  PersonItemList = 'PERSON_ITEM_LIST',
  SimpleImageItemList = 'SIMPLE_IMAGE_ITEM_LIST',
  TimerAction = 'TIMER_ACTION',
  VideoPlayer = 'VIDEO_PLAYER'
}

export type SendEmailVerificationInput = {
  emailSenderId: Scalars['ID'];
};

export type Seo = {
  __typename?: 'Seo';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SeoInput = {
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type SitemapConfig = {
  __typename?: 'SitemapConfig';
  isSearchPage?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type SitemapConfigInput = {
  isSearchPage?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type StringFilterInput = {
  eq?: Maybe<Scalars['String']>;
  ilike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
};

export type SubmitOrderCommand = {
  note?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type Subscriber = {
  __typename?: 'Subscriber';
  data?: Maybe<Scalars['Json']>;
  email?: Maybe<Scalars['String']>;
  formId: Scalars['ID'];
  id: Scalars['ID'];
  labels?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  requestInfo?: Maybe<Scalars['Json']>;
  url?: Maybe<Scalars['String']>;
};

export type SubscriberFilterInput = {
  email?: Maybe<StringFilterInput>;
  formId?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  phone?: Maybe<StringFilterInput>;
};

export type SubscriberListResult = {
  __typename?: 'SubscriberListResult';
  entities: Array<Subscriber>;
  paging: Paging;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  emailSenderCreated: EmailSender;
  emailSenderDeleted: EmailSender;
  emailSenderEdited: EmailSender;
  emailTemplateCreated: EmailTemplate;
  emailTemplateDeleted: EmailTemplate;
  emailTemplateEdited: EmailTemplate;
  entryCreated: Entry;
  entryDeleted: Entry;
  entryEdited: Entry;
  entryTypeCreated: EntryType;
  entryTypeDeleted: EntryType;
  entryTypeEdited: EntryType;
  fieldCreated: Field;
  fieldDeleted: Field;
  fieldEdited: Field;
  formCreated: Form;
  formDeleted: Form;
  formEdited: Form;
  redirectCreated: Redirect;
  redirectDeleted: Redirect;
  redirectEdited: Redirect;
  routeCreated: Route;
  routeDeleted: Route;
  routeEdited: Route;
  taxonCreated: Taxon;
  taxonDeleted: Taxon;
  taxonEdited: Taxon;
  taxonomyCreated: Taxonomy;
  taxonomyDeleted: Taxonomy;
  taxonomyEdited: Taxonomy;
};

export type Taxon = {
  __typename?: 'Taxon';
  breadcrumbs: Array<Breadcrumb>;
  description: Scalars['String'];
  entries: Array<Entry>;
  entriesCount: Scalars['Int'];
  featured: Scalars['Boolean'];
  html: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<ImageValue>;
  images?: Maybe<Array<ImageValue>>;
  insertedAt: Scalars['DateTime'];
  isLeaf: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  level: Scalars['Int'];
  md: Scalars['String'];
  parent?: Maybe<Taxon>;
  parentId?: Maybe<Scalars['ID']>;
  path: Scalars['String'];
  permanentLink: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  pretitle: Scalars['String'];
  richText: Array<Scalars['Json']>;
  seo: Seo;
  shortId: Scalars['ID'];
  slug: Scalars['String'];
  subtitle: Scalars['String'];
  taxonomyCode: Scalars['String'];
  taxonomyId: Scalars['ID'];
  taxons: Array<Taxon>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type TaxonEntriesArgs = {
  filter?: Maybe<EntryFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrderByInput>>;
};


export type TaxonTaxonsArgs = {
  paging?: Maybe<PagingInput>;
};

export type TaxonFieldValue = {
  __typename?: 'TaxonFieldValue';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type TaxonFilterInput = {
  entries?: Maybe<Array<EntryFilterInput>>;
  id?: Maybe<IdFilterInput>;
  isRoot?: Maybe<BooleanFilterInput>;
  lang?: Maybe<Scalars['String']>;
  path?: Maybe<PathFilterInput>;
  shortId?: Maybe<IdFilterInput>;
  slug?: Maybe<StringFilterInput>;
  slugPath?: Maybe<PathFilterInput>;
  taxonomyCode?: Maybe<StringFilterInput>;
  taxonomyId?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
};

export type TaxonListResult = {
  __typename?: 'TaxonListResult';
  entities: Array<Taxon>;
  paging: Paging;
};

export type Taxonomy = {
  __typename?: 'Taxonomy';
  code: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  route: Route;
  routeId: Scalars['ID'];
  routePattern: Scalars['String'];
  taxonsCount: Scalars['Int'];
};

export type TaxonomyFilterInput = {
  code?: Maybe<StringFilterInput>;
  displayName?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
};

export type TaxonomyListResult = {
  __typename?: 'TaxonomyListResult';
  entities: Array<Taxonomy>;
  paging: Paging;
};

export enum TemplateBodyType {
  Html = 'HTML',
  Md = 'MD'
}

export type TestEmailSenderInput = {
  emailSenderId: Scalars['ID'];
  message?: Maybe<TestMessageInput>;
};

export type TestMessageInput = {
  email: Scalars['String'];
  md: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateBlockCommand = {
  blockId: Scalars['ID'];
  root?: Maybe<Scalars['Json']>;
  sections?: Maybe<Scalars['Json']>;
};

export type UpdateBlockTemplateCommand = {
  categories?: Maybe<Array<Scalars['String']>>;
  industries?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateEmailSenderInput = {
  email?: Maybe<Scalars['String']>;
  emailSenderId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
};

export type UpdateEmailTemplateInput = {
  code?: Maybe<Scalars['String']>;
  emailSenderId?: Maybe<Scalars['ID']>;
  emailTemplateId: Scalars['ID'];
  html?: Maybe<Scalars['String']>;
  md?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UpdateEntryCommand = {
  data: UpdateEntryData;
  entryId: Scalars['ID'];
};

export type UpdateEntryData = {
  image?: Maybe<ImageValueInput>;
};

export type UpdateEntryEntriesFieldValueCommand = {
  entryId: Scalars['ID'];
  fieldId: Scalars['ID'];
  valueEntriesId: Array<Scalars['ID']>;
};

export type UpdateEntryEntryFieldValueCommand = {
  entryId: Scalars['ID'];
  fieldId: Scalars['ID'];
  valueEntryId: Scalars['ID'];
};

export type UpdateEntryFieldValueCommand = {
  entryId: Scalars['ID'];
  fieldId: Scalars['ID'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type UpdateEntrySectionValueCommand = {
  code: Scalars['String'];
  entryId: Scalars['ID'];
  type: SectionValueType;
  value: Scalars['Json'];
};

export type UpdateEntryTaxonFieldValueCommand = {
  entryId: Scalars['ID'];
  fieldId: Scalars['ID'];
  valueTaxonId: Scalars['ID'];
};

export type UpdateEntryTaxonsFieldValueCommand = {
  entryId: Scalars['ID'];
  fieldId: Scalars['ID'];
  valueTaxonsId: Array<Scalars['ID']>;
};

export type UpdateEntryTypeCommand = {
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  entryTypeId: Scalars['ID'];
  isProduct?: Maybe<Scalars['Boolean']>;
  routeId?: Maybe<Scalars['ID']>;
};

export type UpdateFieldCommand = {
  code?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['Json']>;
  displayName?: Maybe<Scalars['String']>;
  fieldId: Scalars['ID'];
  /** If type is array, validation schema will be apply to the each children */
  validationSchema?: Maybe<Scalars['Json']>;
};

export type UpdateFormInput = {
  code?: Maybe<Scalars['String']>;
  formId: Scalars['ID'];
  notifyEmails?: Maybe<Array<Scalars['String']>>;
  notifyTemplateId?: Maybe<Scalars['String']>;
  thankYouTemplateId?: Maybe<Scalars['String']>;
};

export type UpdateLayoutCommand = {
  code: Scalars['String'];
  layoutId: Scalars['ID'];
};

export type UpdateLayoutFieldValueCommand = {
  code: Scalars['String'];
  layoutId: Scalars['ID'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type UpdateOrderCommand = {
  billingAddress?: Maybe<AddressInput>;
  note?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  shippingAddress: AddressInput;
};

export type UpdateOrderItemCommand = {
  key?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  variantId: Scalars['ID'];
};

export type UpdatePortalCommand = {
  config?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['Json']>;
};

export type UpdateProductCommand = {
  addOns: Array<ProductAddOnInput>;
  fields: Array<ProductFieldInput>;
  optionTypes: Scalars['Json'];
  optionTypesId: Array<Scalars['ID']>;
  otNames: Scalars['Json'];
  ovNames: Scalars['Json'];
  productId: Scalars['ID'];
  variants: Array<VariantInput>;
};

export type UpdateRedirectCommand = {
  from?: Maybe<Scalars['String']>;
  redirectId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['String']>;
};

export type UpdateRouteCommand = {
  layoutId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  routeId: Scalars['ID'];
  seo?: Maybe<SeoInput>;
  sitemap?: Maybe<SitemapConfigInput>;
};

export type UpdateRouteFieldValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type UpdateRouteSectionConfigCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionConfigType;
  value: Scalars['Json'];
};

export type UpdateRouteSectionThemeCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionThemeType;
  value: Scalars['Json'];
};

export type UpdateRouteSectionValueCommand = {
  code: Scalars['String'];
  routeId: Scalars['ID'];
  type: SectionValueType;
  value: Scalars['Json'];
};

export type UpdateSectionTemplateCommand = {
  categories?: Maybe<Array<Scalars['String']>>;
  code?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  industries?: Maybe<Array<Scalars['String']>>;
  sectionTemplateId: Scalars['ID'];
  theme?: Maybe<Scalars['Json']>;
  value?: Maybe<Scalars['Json']>;
};

export type UpdateTaxonCommand = {
  data: UpdateTaxonData;
  taxonId: Scalars['ID'];
};

export type UpdateTaxonData = {
  image?: Maybe<ImageValueInput>;
};

export type UpdateTaxonParentCommand = {
  parentId?: Maybe<Scalars['ID']>;
  taxonId: Scalars['ID'];
};

export type UpdateTaxonomyCommand = {
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['ID']>;
  taxonomyId: Scalars['ID'];
};

export type UpdateWebsiteCommand = {
  defaultLang?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Scalars['String']>>;
  imageHost?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rootUrl?: Maybe<Scalars['String']>;
  templateVersion?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UpdateWebsiteFieldValueCommand = {
  code: Scalars['String'];
  type: FieldValueType;
  value: Scalars['Json'];
};

export type UpdateWebsiteSectionConfigCommand = {
  code: Scalars['String'];
  type: SectionConfigType;
  value: Scalars['Json'];
};

export type UpdateWebsiteSectionThemeCommand = {
  code: Scalars['String'];
  type: SectionThemeType;
  value: Scalars['Json'];
};

export type UpdateWebsiteSectionValueCommand = {
  code: Scalars['String'];
  type: SectionValueType;
  value: Scalars['Json'];
};


export enum UploadImageStrategy {
  Filename = 'FILENAME',
  FilenameWithSalt = 'FILENAME_WITH_SALT',
  Nanoid = 'NANOID'
}

export type Variant = {
  __typename?: 'Variant';
  bulkPrices: Array<VariantBulkPrice>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Money>;
  optionValues: Array<Scalars['String']>;
  percentageSaved?: Maybe<Scalars['Decimal']>;
  price: Money;
  product: Product;
  productId: Scalars['ID'];
  sku?: Maybe<Scalars['String']>;
};

export type VariantBulkPrice = {
  __typename?: 'VariantBulkPrice';
  price: Money;
  quantity: Scalars['Int'];
};

export type VariantBulkPriceInput = {
  price: MoneyInput;
  quantity: Scalars['Int'];
};

export type VariantInput = {
  bulkPrices: Array<VariantBulkPriceInput>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['String']>;
  listPrice?: Maybe<MoneyInput>;
  optionValues: Array<Scalars['String']>;
  price?: Maybe<MoneyInput>;
  sku?: Maybe<Scalars['String']>;
};

export type VerifyEmailSenderInput = {
  emailSenderId: Scalars['ID'];
};

export type Website = {
  __typename?: 'Website';
  defaultLang: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Scalars['String']>>;
  fieldValues: Array<FieldValue>;
  gtm?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageHost: Scalars['String'];
  isTemplate?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  rawFieldValues: Scalars['Json'];
  rootUrl: Scalars['String'];
  sectionConfigs: Scalars['Json'];
  sectionThemes: Scalars['Json'];
  sectionValues: Scalars['Json'];
  title: Scalars['String'];
  version?: Maybe<Scalars['Json']>;
};

export type WebsiteData = {
  __typename?: 'WebsiteData';
  entryTypes: Array<EntryType>;
  fields: Array<Field>;
  routes: Array<Route>;
  taxonomies: Array<Taxonomy>;
  type: AppDataType;
  website: Website;
  websiteDir?: Maybe<Scalars['String']>;
};

/** Data return for a specific route */
export type WebsiteOrRedirectData = RedirectData | WebsiteData;

export type BasicContentFragment = (
  { __typename?: 'Entry', bodyType?: Maybe<BodyType>, md: string, permanentLink: string, sectionValues: any, seo: (
    { __typename?: 'Seo' }
    & SeoFragment
  ) }
  & EntryCardFragment
);

export type BreadcrumbFragment = { __typename?: 'Breadcrumb', id: string, slug: string, title: string, lang: string };

export type EntryCardFragment = { __typename?: 'Entry', id: string, slug: string, pretitle: string, title: string, subtitle: string, description: string, publishedAt: any, insertedAt: any, updatedAt: any, featured: boolean, entryTypeCode: string, image?: Maybe<{ __typename?: 'ImageValue', id: string, width?: Maybe<any>, height?: Maybe<any> }> };

export type PagingFragment = { __typename?: 'Paging', page: number, pageSize: number, totalEntities: number, totalPages: number };

export type PostCardFragment = (
  { __typename?: 'Entry' }
  & EntryCardFragment
);

export type PostContentFragment = (
  { __typename?: 'Entry', bodyType?: Maybe<BodyType>, md: string, permanentLink: string, sectionValues: any, categories: Array<(
    { __typename?: 'Taxon' }
    & TaxonBreadcrumbItemFragment
  )>, tags: Array<(
    { __typename?: 'Taxon' }
    & TaxonItemFragment
  )>, seo: (
    { __typename?: 'Seo' }
    & SeoFragment
  ) }
  & EntryCardFragment
);

export type SeoFragment = { __typename?: 'Seo', title?: Maybe<string>, description?: Maybe<string>, keywords?: Maybe<Array<string>> };

export type ServiceCardFragment = (
  { __typename?: 'Entry', categories: Array<(
    { __typename?: 'Taxon' }
    & TaxonItemFragment
  )>, tags: Array<(
    { __typename?: 'Taxon' }
    & TaxonItemFragment
  )> }
  & EntryCardFragment
);

export type ServiceContentFragment = (
  { __typename?: 'Entry', bodyType?: Maybe<BodyType>, md: string, sectionValues: any, permanentLink: string, categories: Array<(
    { __typename?: 'Taxon' }
    & TaxonBreadcrumbItemFragment
  )>, tags: Array<(
    { __typename?: 'Taxon' }
    & TaxonItemFragment
  )>, seo: (
    { __typename?: 'Seo' }
    & SeoFragment
  ) }
  & EntryCardFragment
);

export type TaxonBreadcrumbItemFragment = { __typename?: 'Taxon', id: string, slug: string, title: string, taxonomyCode: string, breadcrumbs: Array<(
    { __typename?: 'Breadcrumb' }
    & BreadcrumbFragment
  )> };

export type TaxonItemFragment = { __typename?: 'Taxon', id: string, slug: string, title: string, description: string, taxonomyCode: string, image?: Maybe<{ __typename?: 'ImageValue', id: string }> };

export type CreateSubscriberMutationVariables = Exact<{
  data: CreateSubscriberInput;
}>;


export type CreateSubscriberMutation = { __typename?: 'RootMutationType', createSubscriber?: Maybe<{ __typename?: 'Subscriber', id: string }> };

export type BasicPageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
}>;


export type BasicPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, basic?: Maybe<(
    { __typename?: 'Entry' }
    & BasicContentFragment
  )> };

export type ContactPageQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type ContactPageQuery = { __typename?: 'RootQueryType', routeBy?: Maybe<{ __typename?: 'Route', sectionValues: any, sectionConfigs: any, sectionThemes: any, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }> };

export type IndexPageQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type IndexPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, posts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) }, services: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & ServiceCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };

export type PostPageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
}>;


export type PostPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, post?: Maybe<(
    { __typename?: 'Entry' }
    & PostContentFragment
  )>, relatedPosts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )> }, relatedServices: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & ServiceCardFragment
    )> } };

export type PostCategoryPageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  paging?: Maybe<PagingInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
}>;


export type PostCategoryPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, category?: Maybe<{ __typename?: 'Taxon', id: string, slug: string, title: string, description: string, image?: Maybe<{ __typename?: 'ImageValue', id: string, width?: Maybe<any>, height?: Maybe<any> }>, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, posts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };

export type PostsPageQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
  paging?: Maybe<PagingInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
}>;


export type PostsPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, posts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };

export type ServicePageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
}>;


export type ServicePageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, service?: Maybe<(
    { __typename?: 'Entry' }
    & ServiceContentFragment
  )>, relatedPosts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )> }, relatedServices: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & ServiceCardFragment
    )> } };

export type ServiceCategoryPageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  paging?: Maybe<PagingInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
}>;


export type ServiceCategoryPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, category?: Maybe<{ __typename?: 'Taxon', id: string, slug: string, title: string, description: string, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, projects: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & ServiceCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };

export type ServicesPageQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
  paging?: Maybe<PagingInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
}>;


export type ServicesPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, services: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & ServiceCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };

export type TagPageQueryVariables = Exact<{
  slug: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  paging?: Maybe<PagingInput>;
  orderBy?: Maybe<Array<OrderByInput>>;
}>;


export type TagPageQuery = { __typename?: 'RootQueryType', route?: Maybe<{ __typename?: 'Route', name: string, sectionConfigs: any, sectionThemes: any, sectionValues: any }>, tag?: Maybe<{ __typename?: 'Taxon', id: string, slug: string, title: string, description: string, seo: (
      { __typename?: 'Seo' }
      & SeoFragment
    ) }>, posts: { __typename?: 'EntryListResult', entities: Array<(
      { __typename?: 'Entry' }
      & PostCardFragment
    )>, paging: (
      { __typename?: 'Paging' }
      & PagingFragment
    ) } };
