import { useGetImageSrc, useWebsite } from '@joehua/dew-components'
import React from 'react'

import { SocialShareItem } from './SocialShareItem'

const icons = {
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Facebook"
      role="img"
      viewBox="0 0 512 512"
    >
      <rect width="512" height="512" rx="15%" fill="#1877f2" />
      <path
        d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z"
        fill="#fff"
      />
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Twitter"
      role="img"
      viewBox="0 0 512 512"
    >
      <rect width="512" height="512" rx="15%" fill="#1da1f2" />
      <path
        fill="#fff"
        d="M437 152a72 72 0 01-40 12a72 72 0 0032-40a72 72 0 01-45 17a72 72 0 00-122 65a200 200 0 01-145-74a72 72 0 0022 94a72 72 0 01-32-7a72 72 0 0056 69a72 72 0 01-32 1a72 72 0 0067 50a200 200 0 01-105 29a200 200 0 00309-179a200 200 0 0035-37"
      />
    </svg>
  ),
  telegram: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Telegram"
      role="img"
      viewBox="0 0 512 512"
    >
      <rect width="512" height="512" rx="15%" fill="#37aee2" />
      <path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144" />
      <path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34" />
      <path
        fill="#f6fbfe"
        d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"
      />
    </svg>
  ),
  viber: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Viber"
      role="img"
      viewBox="0 0 512 512"
      fill="#665ca7"
    >
      <rect width="512" height="512" rx="15%" fill="#fff" />
      <path
        fill="none"
        stroke="#665ca7"
        strokeLinecap="round"
        strokeWidth="10"
        d="M269 186a30 30 0 0 1 31 31m-38-58a64 64 0 0 1 64 67m-73-93a97 97 0 0 1 99 104"
      />
      <path d="M288 274q10-13 24-4l36 27q8 10-7 28t-28 15q-53-12-102-60t-61-104q0-20 25-34 13-9 22 5l25 35q6 12-7 22c-39 15 51 112 73 70zM95 232c0 78 14 95 36 118 7 7 32 19 38 19v69c0 4 4 7 8 3l53-63 26 1c144 0 161-56 161-147S400 85 256 85 95 141 95 232zm-30 0c0-126 55-177 191-177s191 51 191 177-55 177-191 177c-10 0-18 0-32-2l-38 43c-7 8-28 11-28-13v-42c-6 0-20-6-39-18-19-13-54-44-54-145z" />
    </svg>
  ),
}

export function SocialShare({
  imageId,
  url,
  message,
}: {
  imageId?: string | null
  url: string
  message?: string
}) {
  const { website } = useWebsite()
  const getImageSrc = useGetImageSrc()
  const imageUrl = getImageSrc({ id: imageId })
  const link = `${website.rootUrl}${url}`
  return (
    <div className="flex">
      <SocialShareItem network="facebook" url={link} title={message || ''}>
        {icons['facebook']}
      </SocialShareItem>
      <SocialShareItem
        network="twitter"
        className="ml-2"
        media={imageUrl || ''}
        url={link}
        title={message || ''}
        description={message}
      >
        {icons['twitter']}
      </SocialShareItem>
      <SocialShareItem
        network="telegram"
        className="ml-2"
        media={imageUrl || ''}
        url={link}
        title={message || ''}
        description={message}
      >
        {icons['telegram']}
      </SocialShareItem>
      <SocialShareItem
        network="viber"
        className="ml-2"
        media={imageUrl || ''}
        url={link}
        title={message || ''}
        description={message}
      >
        {icons['viber']}
      </SocialShareItem>
    </div>
  )
}
