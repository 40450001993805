import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  ssrExchange,
} from '@urql/core'
import { SSRExchange } from '@urql/core/dist/types/exchanges/ssr'
import { WebsiteProcessor } from 'dew-ssr'

const isServerSide = typeof window === 'undefined'

declare global {
  interface Window {
    __URQL_DATA__: Record<string, any>
  }
}

export const ssr: SSRExchange = ssrExchange({
  isClient: !isServerSide,
  initialState: !isServerSide ? window.__URQL_DATA__ : undefined,
})

export const createSsr = () =>
  ssrExchange({
    isClient: !isServerSide,
    initialState: !isServerSide ? window.__URQL_DATA__ : undefined,
  })

export function initializeClient({
  endpoint,
  domain,
  ssr,
}: {
  endpoint: string
  domain: string
  ssr: SSRExchange
}) {
  return createClient({
    url: `${endpoint}/graphql`,
    suspense: isServerSide,
    fetchOptions: {
      headers: {
        'x-website-name': WebsiteProcessor.getDomain(domain),
      },
    },
    exchanges: [dedupExchange, cacheExchange, ssr, fetchExchange],
  })
}
