declare global {
  interface ImportMeta {
    env: Record<string, string>
  }
}

export function getEndpoint(): string {
  const endpoint =
    typeof window !== 'undefined' && window.ENDPOINT
      ? window.ENDPOINT
      : import.meta.env.VITE_ENDPOINT

  if (!endpoint) {
    throw new Error('endpoint is not configured correctly!')
  }
  return endpoint
}
