import { Image } from '@joehua/dew-components'
import React from 'react'

import { ServiceCardFragment } from '../../generated/graphql'
import { Link } from '../Link'

export function ServiceCard({ entity }: { entity: ServiceCardFragment }) {
  const to = { name: 'service', lang: 'vi', params: { slug: entity.slug } }

  return (
    <Link
      to={to}
      className="no-underline bg-white transition ease-in duration-200 flex flex-1 flex-col rounded-sm overflow-hidden h-full img-zoom-on-hover border border-neutral-100"
    >
      <div className="overflow-hidden w-full relative ar-16/9 mb-6">
        <Image
          className="absolute inset-0 rounded w-full h-full object-cover object-center block"
          data={{
            value: { id: entity.image?.id, alt: entity.title, title: entity.title },
            config: { transform: 'w_400,h_225', lazyload: false },
          }}
        />
        <div className="absolute absolute-fill flex justify-start items-start text-center p-2">
          {entity.featured && (
            <div className="bg-accent-600 inline-block px-2 py-1 uppercase text-neutral-100 rounded-sm font-bold text-xs">
              Nổi bật
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="px-5 pt-4 pb-8 text-center">
          <h3 className="text-xl text-neutral-900 leading-snug mb-3 font-sans line-clamp line-clamp-2 uppercase font-bold">
            {entity.title}
          </h3>
          <p className="leading-relaxed text-neutral-800 line-clamp line-clamp-3 px-2">
            {entity.description}
          </p>
        </div>
      </div>
    </Link>
  )
}
