import React from 'react'
import { Helmet } from 'react-helmet-async'

import { SeoFragment } from '../../generated/graphql'

export function Seo({ seo }: { seo: SeoFragment }) {
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description || ''}></meta>
    </Helmet>
  )
}
