import { ImageItem, InfoItem, SectionsOverride } from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { Seo } from '../../components/Seo'
import { ServiceCardList } from '../../components/ServiceCardList'
import { ServicesPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function ServicesPage() {
  const [{ data, fetching }] = useRouteQuery<ServicesPageQuery>('Services')
  const { services, route } = data ?? {}

  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <ImageItem
        data={{
          valueKey: 'hero',
          themeKey: 'hero',
          config: {
            infoItem: {
              pretitleAs: 'h3',
              titleAs: 'h1',
              subtitleAs: 'h2',
            },
            useBackground: true,
            image: { transform: 'w_1200' },
          },
        }}
      />
      <div className="max-w-screen-xl mx-auto">
        {fetching && <Loading />}
        {route && <Seo seo={route.seo} />}
        {services?.entities.length ? (
          <section className="py-4 lg:py-20 px-3">
            <ServiceCardList entities={services?.entities} />
          </section>
        ) : null}
      </div>
    </SectionsOverride>
  )
}
