import { ImageItem, SectionsOverride } from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { Seo } from '../../components/Seo'
import { PostCategoryPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function PostCategoryPage() {
  const [{ data, fetching }] = useRouteQuery<PostCategoryPageQuery>('PostCategory')
  const { posts, route, category } = data ?? {}

  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <ImageItem
        data={{
          value: {
            image: { id: category?.image?.id, alt: category?.title },
            infoItem: {
              title: category?.title || 'Tin tức',
              description: category?.description || '',
            },
          },
          themeKey: 'hero',
          config: {
            infoItem: {
              pretitleAs: 'h3',
              titleAs: 'h1',
              subtitleAs: 'h2',
            },
            useBackground: true,
            image: { transform: 'w_1200' },
          },
        }}
      />
      <section className="max-w-screen-xl mx-auto py-10 lg:py-20 px-3">
        {fetching && <Loading />}
        {category && (
          <>
            <Seo seo={category.seo} />
          </>
        )}
        {posts?.entities.length ? (
          <section>
            <PostCardList entities={posts?.entities} />
          </section>
        ) : null}
      </section>
    </SectionsOverride>
  )
}
