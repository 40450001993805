import {
  Gallery,
  ImageItem,
  ImageItemCarousel,
  InfoItem,
  SectionsOverride,
} from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { Seo } from '../../components/Seo'
import { ServiceCardList } from '../../components/ServiceCardList'
import { IndexPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function IndexPage() {
  const [{ data, fetching }] = useRouteQuery<IndexPageQuery>('Index')
  const { posts, services, route } = data ?? {}

  return (
    <>
      {fetching && !data && <Loading />}
      <SectionsOverride
        sectionConfigs={route?.sectionConfigs || {}}
        sectionThemes={route?.sectionThemes || {}}
        sectionValues={route?.sectionValues || {}}
      >
        {route && <Seo seo={route.seo} />}
        <ImageItemCarousel
          data={{
            valueKey: 'hero_slider',
            value: {
              items: [
                {
                  image: { id: 'car-7.jpg' },
                  infoItem: {
                    pretitle: '',
                    title: 'Detailing đích thực',
                    description:
                      'An toàn tối đa - Quy trình chuyên nghiệp - Chi phí hợp lý - Tư vấn chính xác',
                  },
                },
                {
                  image: { id: 'car-8.jpg' },
                  infoItem: {
                    pretitle: '',
                    title: 'Detailing đích thực',
                    description:
                      'An toàn tối đa - Quy trình chuyên nghiệp - Chi phí hợp lý - Tư vấn chính xác',
                  },
                },
                {
                  image: { id: 'car-9.jpg' },
                  infoItem: {
                    pretitle: '',
                    title: 'Detailing đích thực',
                    description:
                      'An toàn tối đa - Quy trình chuyên nghiệp - Chi phí hợp lý - Tư vấn chính xác',
                  },
                },
              ],
            },
            config: {
              item: { useBackground: true, image: { transform: 'w_1600' } },
              showDots: true,
              showArrows: true,
            },
            theme: {
              root: 'w-full relative',
              arrowWrapper: '',
              leftArrow:
                'left-0 top-50 transform md:translate-x-6 translate-y-64 hidden md:block w-10 h-10 bg-neutral-100 opacity-25 rounded-full cursor-pointer hover:opacity-100 transition ease-in duration-200',
              rightArrow:
                'right-0 top-50 transform md:-translate-x-6 translate-y-64 hidden md:block w-10 h-10 bg-neutral-100 opacity-25 rounded-full cursor-pointer hover:opacity-100 transition ease-in duration-200',
              dotsWrapper: 'flex items-center justify-center cursor-pointer z-20 -mt-16',
              dot: 'mx-4 w-4 h-4',
              inactiveDot:
                'p-2 text-neutral-800 w-3 h-3 rounded-full bg-neutral-400 transform scale-90 transition ease-in duration-200',
              activeDot:
                'rounded-full bg-neutral-200 text-neutral-400 transform scale-100 transition ease-in duration-200',
              item: {
                root: 'w-full relative ar-9/16 md:ar-4/3 lg:ar-16/9 bg-cover bg-center',
                imageWrapper: '',
                image: 'absolute inset-0',
                infoItemWrapper: 'absolute inset-0 z-20',
                infoItem: {
                  root:
                    'flex flex-col justify-center w-full h-full text-center md:max-w-xl lg:max-w-2xl mx-auto px-4',
                  pretitle:
                    'font-sans uppercase tracking-wide text-accent-300 inline-block',
                  title:
                    'font-serif text-4xl lg:text-6xl font-bold uppercase leading-loose lg:leading-normal bg-clip-text text-transparent bg-gradient-to-r from-neutral-100 via-neutral-400 to-neutral-100',
                  description:
                    'font-sans lg:text-2xl font-semibold w-full text-neutral-100 my-3',
                  nav: {
                    root: 'flex w-full justify-center',
                    itemWrapper: 'p-2',
                    item: {
                      root:
                        'h-10 px-6 lg:px-8 font-sans bg-accent-700 text-neutral-100 uppercase font-semibold rounded-sm hover:bg-accent-700 transition ease-in duration-200 inline-flex items-center justify-center cursor-pointer',
                    },
                  },
                },
                overlay:
                  'absolute inset-0 bg-gradient-to-t from-black via-alpha-60 to-alpha-10 z-10',
              },
            },
          }}
        />

        <div className="relative">
          <ImageItem
            data={{
              valueKey: 'gioi_thieu',
              // value: {
              //   image: { id: 'car-wash-new.png' },
              //   infoItem: {
              //     pretitle: 'Bởi vì…',
              //     title: 'Người bạn đồng hành xứng đáng được đối xử đặc biệt',
              //     description:
              //       'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even.',
              //     labelValueList: {
              //       items: [
              //         {
              //           icon: 'sun',
              //           label: 'High Quality Service',
              //           value:
              //             'by injected humour, or randomised words which dont look even slightly believable If you are going',
              //         },
              //         {
              //           icon: 'sun',
              //           label: 'Best Price in Market',
              //           value:
              //             'by injected humour, or randomised words which dont look even slightly believable If you are going',
              //         },
              //       ],
              //     },
              //     nav: {
              //       items: [
              //         {
              //           title: 'Về chúng tôi',
              //           type: 'link',
              //           to: { lang: 'vi', name: 'basic', params: { slug: 'gioi-thieu' } },
              //         },
              //       ],
              //     },
              //   },
              // },
              theme: {
                root:
                  'flex flex-wrap justify-center bg-gradient-to-b from-black to-neutral-900 w-full py-20',
                imageWrapper: 'w-full lg:w-1/2',
                infoItemWrapper: 'w-full lg:w-1/2 justify-center mt-6 lg:mt-0',
                infoItem: {
                  root: 'flex flex-col max-w-2xl mx-auto px-4',
                  pretitle: 'py-1 inline-block text-neutral-100 text-xl font-bold',
                  title:
                    'font-serif font-bold text-3xl lg:text-5xl leading-snug lg:leading-snug bg-clip-text text-transparent bg-gradient-to-r from-neutral-100 via-neutral-400 to-neutral-100',
                  subtitle: 'text-md text-neutral-100 uppercase',
                  description: 'leading-relaxed text-neutral-100 mt-4',
                  labelValueList: {
                    root: 'flex flex-wrap -m-4 mt-2',
                    itemWrapper: 'w-full',
                    item: {
                      root: 'flex items-start justify-center rounded-md mt-6',
                      icon: 'w-10 h-10 text-neutral-100',
                      iconWrapper: 'flex-none mt-2 mr-4',
                      contentWrapper: 'flex-auto',
                      label:
                        'text-white text-lg font-sans font-bold uppercase tracking-wide',
                      value: 'font-body text-neutral-100 block',
                    },
                  },
                  nav: {
                    root: 'mt-12',
                    item: {
                      root:
                        'bg-gradient-to-r from-accent-600 via-accent-400 to-accent-600 shadow-sm h-12 inline-flex items-center justify-center px-6 rounded-sm transform hover:-translate-y-1 transition ease-in duration-200',
                      text: 'uppercase font-bold',
                    },
                  },
                },
              },
              config: {
                image: { transform: 'w_800' },
                infoItem: { useMarkdown: true },
              },
            }}
          />
        </div>

        {services?.entities.length ? (
          <section className="py-16 lg:py-32 bg-neutral-100">
            <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
              <InfoItem
                data={{
                  valueKey: 'tieu_de_dich_vu',
                  theme: {
                    root: 'mb-10 max-w-xl mx-auto text-center',
                    pretitle:
                      'py-1 inline-block text-neutral-900 text-xl font-semibold uppercase',
                    title:
                      'block font-serif font-bold text-3xl lg:text-5xl leading-tight text-neutral-900 uppercase',
                    description: 'block leading-relaxed text-neutral-900 mt-4',
                  },
                }}
              />
              <ServiceCardList entities={services?.entities} />
            </div>
          </section>
        ) : null}
        <ImageItem
          data={{
            valueKey: 'why_us',
            // value: {
            //   image: { id: 'metalic-texture.jpg' },
            //   infoItem: {
            //     title: 'LCA được khách hàng yêu mến vì',
            //     labelValueList: {
            //       items: [
            //         {
            //           icon: 'heart',
            //           label: '30+',
            //           value: 'chuyên viên Detailing yêu nghề',
            //         },
            //         {
            //           icon: 'heart',
            //           label: '3+',
            //           value: 'năm kinh nghiệm',
            //         },
            //         {
            //           icon: 'heart',
            //           label: '300+',
            //           value: 'khách hàng vote 5 sao',
            //         },
            //         {
            //           icon: 'smile',
            //           label: '300+',
            //           value: 'khách hàng vote 5 sao',
            //         },
            //       ],
            //     },
            //   },
            // },
            theme: {
              root:
                'w-full relative ar-9/16 md:ar-4/3 lg:ar-21/9 bg-fixed bg-cover bg-center',
              overlay: 'absolute inset-0 w-full h-full bg-black bg-opacity-60',
              imageWrapper: 'relative ar-16/9 overflow-hidden',
              image: 'w-full absolute inset-0 object-cover object-center',
              infoItemWrapper: 'absolute flex items-center justify-center inset-0',
              infoItem: {
                root:
                  'flex flex-col items-center justify-center w-full h-full max-w-6xl mx-auto text-center p-4',
                pretitle: 'uppercase pt-20 mb-2 text-neutral-100 font-sans',
                title:
                  'block font-serif font-bold text-3xl lg:text-5xl leading-tight text-neutral-100 uppercase',
                subtitle: 'max-w-lg mx-auto text-center text-neutral-100 font-sans mt-3',
                description:
                  'max-w-lg mx-auto text-center text-neutral-100 font-sans mt-3',
                labelValueList: {
                  root: 'flex flex-wrap w-full mx-0',
                  itemWrapper: 'w-1/2 md:w-1/4 p-1 lg:p-4',
                  item: {
                    root:
                      'flex flex-col items-center justify-center p-1 md:p-2 text-center w-full h-full',
                    iconWrapper:
                      'bg-white p-3 md:p-4 rounded-full flex-none transform translate-y-6 md:translate-y-10 shadow-md',
                    icon: 'w-6 h-6 md:w-10 md:h-10 text-neutral-800',
                    contentWrapper:
                      'bg-neutral-200 px-3 md:px-6 md:pb-8 pt-6 pb-4 md:pt-10 rounded-md flex flex-col w-full h-full justify-between items-stretch shadow-md',
                    label:
                      'my-3 text-lg md:text-xl lg:text-3xl uppercase font-sans font-semibold',
                    value: 'h-full font-body md:px-3',
                  },
                },
                nav: {
                  root: 'flex w-full justify-center mt-4',
                  itemWrapper: 'p-2',
                  item: {
                    root:
                      'h-10 px-6 lg:px-8 font-sans bg-accent-700 text-neutral-100 uppercase font-semibold rounded-sm hover:bg-accent-700 transition ease-in duration-200 inline-flex items-center justify-center cursor-pointer',
                    rightIcon: 'w-4 h-4 ml-1',
                  },
                },
              },
            },
            config: {
              useBackground: true,
              image: { transform: 'w_2400' },
            },
          }}
        />
        <InfoItem
          data={{
            valueKey: 'the_manh',
            theme: {
              root: 'py-20 lg:py-32',
              title:
                'block font-serif font-bold text-3xl lg:text-5xl leading-tight text-neutral-900 uppercase text-center',
              labelValueList: {
                root: 'flex flex-wrap md:max-w-2xl lg:max-w-screen-xl mx-auto mt-8',
                itemWrapper: 'w-full md:1/2 lg:w-1/4 p-3',
                item: {
                  root: 'flex items-start justify-center rounded-md mt-6',
                  icon: 'w-6 h-6 text-neutral-900',
                  iconWrapper: 'flex-none mr-4',
                  contentWrapper: 'flex-auto',
                  label:
                    'text-neutral-900 text-lg font-sans font-bold uppercase tracking-wide',
                  value: 'font-body text-neutral-900 block mt-2',
                },
              },
            },
          }}
        />
        <section className="py-20 bg-black">
          <Gallery
            data={{
              valueKey: 'logo_list',
              theme: {
                root: 'flex flex-wrap max-w-6xl mx-auto items-center',
                itemWrapper: 'w-1/2 md:w-1/4 p-4',
                item: {
                  root: 'flex items-center justify-center select-none',
                },
              },
            }}
          />
        </section>
        {posts?.entities.length ? (
          <section className="py-10 lg:py-20 bg-neutral-100">
            <div className="md:max-w-3xl lg:max-w-screen-xl mx-auto px-3">
              <InfoItem
                data={{
                  valueKey: 'tieu_de_blog',
                  theme: {
                    root: 'mb-10 max-w-xl mx-auto text-center',
                    pretitle:
                      'py-1 inline-block text-neutral-900 text-xl font-semibold uppercase',
                    title:
                      'block font-serif font-bold text-3xl lg:text-5xl leading-tight text-neutral-900 uppercase',
                    description: 'block leading-relaxed text-neutral-900 mt-4',
                  },
                }}
              />
              <PostCardList entities={posts?.entities} />
            </div>
          </section>
        ) : null}
      </SectionsOverride>
    </>
  )
}
