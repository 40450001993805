import React from 'react'

import { PostCardFragment } from '../../generated/graphql'
import { PostCard } from '../PostCard'

export function PostCardList({ entities }: { entities: PostCardFragment[] }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {entities.map((x) => (
        <PostCard key={x.id} entity={x} />
      ))}
    </div>
  )
}
