import React from 'react'

import { TocItem } from '../../components/markdownOverrides'

export function Toc({ items, maxLevel = 2 }: { items: TocItem[]; maxLevel?: number }) {
  return (
    <ul className="">
      {items
        .filter((x) => x.level <= maxLevel)
        .map((x) => (
          <li key={x.id} className="mb-1 list-disc ml-6">
            <a
              href={`#${x.id}`}
              className="block text-sm font-sans text-neutral-600 transition ease-in duration-75 hover:text-accent-600"
            >
              {x.text}
            </a>
          </li>
        ))}
    </ul>
  )
}
