import { ImageItem, InfoItem, SectionsOverride } from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { Seo } from '../../components/Seo'
import { PostsPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function PostsPage() {
  const [{ data, fetching }] = useRouteQuery<PostsPageQuery>('Posts')
  const { posts, route } = data ?? {}

  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <ImageItem
        data={{
          value: {
            image: { id: 'car-11.jpg' },
            infoItem: {
              title: 'Ask-a-pro Blog',
              description: '',
            },
          },
          themeKey: 'hero',
          config: {
            infoItem: {
              pretitleAs: 'h3',
              titleAs: 'h1',
              subtitleAs: 'h2',
            },
            useBackground: true,
            image: { transform: 'w_1200' },
          },
        }}
      />
      <div className="max-w-screen-xl mx-auto">
        {fetching && <Loading />}
        {route && <Seo seo={route.seo} />}
        {posts?.entities.length ? (
          <section className="py-4 lg:py-20 px-3">
            <PostCardList entities={posts?.entities} />
          </section>
        ) : null}
      </div>
    </SectionsOverride>
  )
}
