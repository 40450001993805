import { ImageItem, InfoItem, Markdown, SectionsOverride } from '@joehua/dew-components'
import { mergeDeepRight } from 'ramda'
import React from 'react'

import { EntryNotFound } from '../../components/EntryNotFound'
import { Loading } from '../../components/Loading'
import { markdownOverrides } from '../../components/markdownOverrides'
import { Seo } from '../../components/Seo'
import { BasicPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function BasicPage() {
  const [{ data, fetching }] = useRouteQuery<BasicPageQuery>('Basic')

  const { route, basic } = data ?? {}

  return (
    <>
      {fetching && <Loading />}

      {basic ? (
        <SectionsOverride
          sectionConfigs={route?.sectionConfigs}
          sectionThemes={route?.sectionThemes}
          sectionValues={mergeDeepRight(route?.sectionValues, basic.sectionValues)}
        >
          <ImageItem
            data={{
              value: {
                image: { id: basic.image?.id, alt: basic.title },
                infoItem: {
                  title: basic.title,
                  subtitle: basic?.subtitle,
                  pretitle: basic?.pretitle,
                },
              },
              themeKey: 'hero',
              config: {
                infoItem: {
                  pretitleAs: 'h3',
                  titleAs: 'h1',
                  subtitleAs: 'h2',
                },
                useBackground: true,
                image: { transform: 'w_1200' },
              },
            }}
          />
          <Seo seo={basic.seo} />
          <div className="rich-text px-3 md:max-w-2xl lg:max-w-4xl mx-auto mb-16 mt-12 lg:mb-20">
            {basic.md.length > 0 ? (
              <Markdown
                data={{ value: { md: basic.md } }}
                overrides={markdownOverrides}
              />
            ) : (
              <div>Đang cập nhật</div>
            )}
          </div>
        </SectionsOverride>
      ) : (
        <EntryNotFound />
      )}
    </>
  )
}
