import { VideoPlayerData } from '@joehua/dew-components'
import React from 'react'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'

function getVideoId(url?: string) {
  if (!url) {
    return null
  }
  const tmp = /youtube.com\/watch\?v=(.*)/.exec(url)
  return tmp?.[1]
}

export function VideoPlayer({ data }: { data: VideoPlayerData }) {
  const id = getVideoId(data.value?.url)
  return <LiteYouTubeEmbed id={id} poster="hqdefault" adNetwork={false} noCookie />
}
