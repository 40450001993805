import { InfoItem, SectionsOverride } from '@joehua/dew-components'
import React from 'react'

import { Loading } from '../../components/Loading'
import { PostCardList } from '../../components/PostCardList'
import { Seo } from '../../components/Seo'
import { TagPageQuery } from '../../generated/graphql'
import { useRouteQuery } from '../../urql/useRouteQuery'

export function TagPage() {
  const [{ data, fetching }] = useRouteQuery<TagPageQuery>('Tag')
  const { posts, route, tag } = data ?? {}

  return (
    <SectionsOverride
      sectionConfigs={route?.sectionConfigs || {}}
      sectionThemes={route?.sectionThemes || {}}
      sectionValues={route?.sectionValues || {}}
    >
      <section className="max-w-screen-xl mx-auto py-10 lg:py-20 px-3">
        {fetching && <Loading />}
        {tag && (
          <>
            <Seo seo={tag.seo} />
          </>
        )}
        {posts?.entities.length ? (
          <section>
            <InfoItem
              data={{
                value: {
                  title: tag?.title || 'Tag',
                  description: tag?.description || '',
                },
                theme: {
                  root: 'mb-6',
                  pretitle: 'uppercase font-body tracking-wide font-body',
                  title: 'text-3xl lg:text-4xl w-full font-serif block',
                  description: 'rich-text block',
                },
                config: {
                  titleAs: 'h1',
                  descriptionAs: 'h2',
                },
              }}
            />
            <PostCardList entities={posts?.entities} />
          </section>
        ) : null}
      </section>
    </SectionsOverride>
  )
}
