import { Form, Image, InfoItem, Markdown, Nav, useWebsite } from '@joehua/dew-components'
import React from 'react'

export function Footer() {
  const {
    website: { sectionValues },
  } = useWebsite()
  const logo = sectionValues?.Image?.logo
  return (
    <footer className="bg-neutral-900">
      <div className="h-2 w-full bg-neutral-800" />
      <div className="max-w-6xl mx-auto py-12 flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-1/2 px-4">
          <Image
            className="h-16"
            data={{
              value: {
                ...logo,
                wrapperNavItem: { type: 'link', to: { name: 'index', lang: 'vi' } },
              },
              theme: { root: 'w-64 inline-flex' },
            }}
          />

          <InfoItem
            data={{
              valueKey: 'thong_tin_lien_he_chinh',
              theme: {
                root: 'mt-6',
                title: 'text-neutral-200 block uppercase font-semibold text-xl',
                description: 'text-neutral-300 block max-w-sm italic mt-2',
                labelValueList: {
                  root: 'mt-6',
                  itemWrapper: 'w-full flex flex-row mb-2',
                  item: {
                    root: 'flex items-start',
                    iconWrapper:
                      'flex-none mr-2 three-d-shadow p-2 rounded-full inline-flex items-center justify-center',
                    icon: 'w-4 h-4 text-neutral-300',
                    contentWrapper: 'flex-auto items-center text-neutral-300 mt-1',
                    label: 'font-semibold mr-1',
                    value: '',
                  },
                },
              },
            }}
          />
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3 px-4 mt-10 md:mt-0">
          <InfoItem
            data={{
              valueKey: 'form_footer_tieu_de',
              theme: {
                root: 'mb-3',
                title: 'text-neutral-200 block uppercase font-semibold text-xl',
              },
            }}
          />
          <Form
            data={{
              valueKey: 'form_lien_he_chinh',
              themeKey: 'form_footer',
            }}
          />
        </div>
      </div>
      <div className="border-t border-accent-800 pt-3 pb-6 text-neutral-500 text-center text-xs flex items-baseline flex-col md:flex-row justify-between max-w-6xl mx-auto">
        <Nav
          data={{
            valueKey: 'menu_footer',
            theme: {
              root: 'flex justify-center mb-4',
              itemWrapper: '',
              item: { root: 'text-xs text-neutral-500 p-2' },
            },
          }}
        />
        <Markdown
          data={{
            valueKey: 'copyright',
            theme: { root: 'text-center rich-text text-neutral-200' },
          }}
        />
        <Nav
          data={{
            valueKey: 'lien_ket_mang_xa_hoi',
            theme: {
              root: 'flex mt-3 lg:mt-6',
              itemWrapper: 'p-1',
              item: {
                root:
                  'three-d-shadow p-2 rounded-full inline-flex items-center justify-center ',
                icon: 'w-4 h-4 text-neutral-500',
              },
            },
          }}
        />
      </div>
    </footer>
  )
}
